/* header */

.house-header .show-on-mobile {
    display: none;
}

.mob.mobile-menu-main {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 90px;
    z-index: 9999;
}

.mobile-menu-main .mobile-menu {
    position: fixed;
    width: 70px;
    background: var(--green);
    height: 70px;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-menu-main .mobile-menu button {
    width: 34px;
    height: 8px;
    position: absolute;
    right: 19px;
    background: transparent;
}

.mobile-menu-main .mobile-menu-link {
    position: absolute;
    right: 97%;
    background: var(--green);
    top: 0;
    width: 94vw;
    height: 100vh;
    margin-right: auto;
}

.mobile-menu-link .privacy-policy a {
    color: var(--white) !important;
    font-size: 14px;
    text-decoration: underline !important;
}

.mobile-menu-link .social-links {
    justify-content: center;
    align-items: center;
}

.mobile-menu button span:first-child {
    background: var(--white);
    width: 100%;
    display: block;
    height: 2px;
    position: absolute;
    top: 0;
    left: 0;
}

.mobile-menu button span:last-child {
    background: var(--white);
    width: 100%;
    display: block;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
}

.mobile-menu.active button span:first-child {
    transform: rotate(134deg);
    top: 2px;
    left: 0;
}

.mobile-menu.active button span:last-child {
    transform: rotate(45deg);
    bottom: 4px;
    left: 0;
}

.web-header {
    position: absolute;
    padding-top: 40px;
    z-index: 999;
}

.header-logo-main .header-logo {
    width: 230px;
    height: 234px;
    overflow: hidden;
    display: block;
    position: relative;
}

.header-logo-main .header-logo img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
}

.header-logo-main {
    position: relative;
    display: grid;
    grid-template-columns: repeat(1, 236px 350px);
    gap: 50px;
    padding-left: 90px;
    width: 100%;
}

.header-logo-main .member {
    font-weight: 500;
    color: var(--yellow) !important;
    display: block;
    position: relative;
    text-decoration: none;
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    width: max-content;
    height: -moz-fit-content;
    height: fit-content;
}

.header-logo-main .member::after {
    position: absolute;
    content: "";
    height: 1.5px;
    width: 100%;
    background: var(--yellow);
    left: 0;
    bottom: 0;
}

.header-logo-main  .header-logo .fixed-menu-logo {
    display: none;
}

.web-header .social-left {
    background: var(--yellow);
    position: relative;
    height: 100%;
    width: 100px;
    display: grid;
    grid-template-columns: 1fr;
}

.web-header .social-left .top {
    position: relative;
    padding-top: 50px;
}

.web-header .social-left .social-top {
    position: relative;
    padding-left: 0;
    display: grid;
    justify-content: center;
    align-items: center;
    gap: 45px;
}

.web-header .social-left .social-top .header-search {
    position: relative;
    height: 24px;
    width: 24px;
    display: block;
    overflow: hidden;
    margin: 0 auto;
}

.web-header .social-left .social-top .header-search img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
}

.web-header .social-left .social-top .header-search .hover-img {
    display: none;
}

.web-header .social-left .social-top .header-search:hover .hover-img {
    display: block;
}

.web-header .social-left .social-top .header-cart {
    position: relative;
    height: 21px;
    width: 24px;
    display: block;
    overflow: inherit;
    margin: 0 auto;
}

.web-header .social-left .social-top .header-cart .cart-value {
    position: absolute;
    top: -13px;
    right: -12px;
    z-index: 999;
    background: var(--green);
    color: var(--white);
    border-radius: 100%;
    width: 20px;
    height: 20px;
    padding: 5px;
    font-size: 11px;
    line-height: 1;
    text-align: center;
}

.web-header .social-left .social-top .header-cart img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
}

.web-header .social-left .social-top .header-cart .hover-img {
    display: none;
}

.web-header .social-left .social-top .header-cart:hover .hover-img {
    display: block;
}

.web-header .social-left .social-top .header-cart:hover .real-img {
    display: none;
}

.web-header .social-left .social-top .header-account {
    position: relative;
    height: 24px;
    width: 24px;
    display: block;
    overflow: hidden;
    margin: 0 auto;
}

.web-header .social-left .social-top .header-account img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
}

.web-header .social-left .social-top .header-account:hover .real-img {
    display: none;
}

.web-header .social-left .social-top .header-account .hover-img {
    display: none;
}

.web-header .social-left .social-top .header-account:hover .hover-img {
    display: block;
}

.web-header .social-left .header-whatsapp {
    position: relative;
    height: 36px;
    width: 36px;
    display: block;
    overflow: hidden;
    margin: 0 auto;
}

.web-header .social-left .header-whatsapp img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
}

.web-header .social-left .header-whatsapp .hover-img {
    display: none;
}

.web-header .social-left .header-whatsapp:hover .hover-img {
    display: block;
}

.web-header .social-left .header-whatsapp:hover .real-img {
    display: none;
}

.web-header .social-left .bottom {
    display: flex;
    align-self: end;
    margin-bottom: 40px;
}

.web-header .left-sidebar {
    position: fixed;
    right: 0;
    top: 0;
    left: auto;
    bottom: 0;
    height: 100vh;
    width: 15.6%;
    display: flex;
    justify-content: end;
    align-items: flex-start;
    box-shadow: -2px 0px 10px;
    z-index: 999;
}

.web-header .left-sidebar ul.cost-nav {
    display: flex;
    margin-bottom: 0;
    height: 100%;
    justify-content: flex-end;
    padding-left: 0;
}

.web-header .left-sidebar .link {
    height: 100%;
    width: 50px;
    background: var(--dark-green);
    justify-content: center;
    display: flex;
    -webkit-transition: all 0.3s ease-out 0s !important;
    -moz-transition: all 0.3s ease-out 0s !important;
    -ms-transition: all 0.3s ease-out 0s !important;
    -o-transition: all 0.3s ease-out 0s !important;
    transition: all 0.3s ease-out 0s !important;
}

.web-header .left-sidebar .link:hover {
    background: var(--white);
}

.web-header .left-sidebar .cost-link {
    cursor: pointer;
    writing-mode: tb;
    transform: rotate(360deg);
    display: block;
    color: var(--white) !important;
    text-decoration: none;
    font-size: 22px;
    line-height: 45px;
    font-weight: 500;
    height: 100%;
    width: 100%;
    padding-top: 50px;
    transition: all 0.3s ease-out 0s !important;
    font-family: var(--lora);
}

.web-header .left-sidebar .link:hover .cost-link {
    color: var(--dark-green) !important;
}

.web-header .left-sidebar .link .cost-link.active {
    background: var(--white);
    color: var(--dark-green) !important;
}

.mobile-menu-link .top-main {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    max-width: 73.5%;
    padding: 30px 35px;
}

.mobile-menu-link .mobile-social {
    position: relative;
    border-top: 2px solid var(--yellow);
    margin-top: 56px;
}

.mobile-menu-link .mobile-social .f-right {
    display: grid;
    grid-template-columns: 60% 40%;
    padding-left: 20.5%;
    padding-top: 36px;
    justify-content: center;
    align-items: center;
}

.mobile-menu-link .privacy-policy {
    display: flex;
    gap: 20px;
}


.mobile-menu-link .mobile-link .cost-link {
    font-size: 40px;
    line-height: 60px;
    color: var(--white) !important;
    text-transform: uppercase;
}

.mobile-menu-link .mobile-link {
    padding-left: 23.5%;
}

.mobile-menu-link .mobile-link .link {
    margin-bottom: 9px;
}

.mobile-menu-main .mobile-menu-link .cta button {
    position: relative;
    width: max-content;
    height: auto;
}

.mobile-menu-main .mobile-menu-link .cta button span {
    transform: rotate(0deg);
    position: relative;
    background: transparent;
    margin-left: 6px;
    height: -moz-fit-content;
    height: fit-content;
    width: 20px;
    display: inline-block;
    transition: all 200ms ease-in;
    background: url("/public/assets/image/arrow-yellow-bg.png") no-repeat center;
}

.mobile-menu-main .mobile-menu-link .cta button:hover span {
    background: url("/public/assets/image/arrow-right-white.png") no-repeat center;
}

.mobile-menu-link .top-main .header-logo {
    height: 154px;
    width: 152px;
}

.mobile-menu-link .top-main .header-logo img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.mobile-menu-link .top-main a.sub-logo {
    display: none;
}

 /* footer start */
 .house-footer {
    position: relative;
    padding: 66px 0px 87px 90px;
}

.house-footer::before {
    position: absolute;
    content: "";
    width: 84.4%;
    height: 1.5px;
    background: var(--yellow);
    left: 0;
    top: 0;
}

.house-footer .footer-top {
    display: grid;
    grid-template-columns: 13.8% 13.8% 13.8% 24.7% 1fr;
    gap: 70px;
}

.house-footer .footer-top .f-title {
    position: relative;
    display: block;
    padding-bottom: 12px;
    width: 100%;
    margin-bottom: 11px;
}

.house-footer .footer-top .f-title .heading-h5 {
    font-weight: 600;
}

.house-footer .footer-top .f-detail p {
    font-family: var(--indivisible);
}

.house-footer .footer-top .f-title::after {
    position: relative;
    content: " ";
    height: 2px;
    width: 100%;
    background: var(--dark-green);
    bottom: -7px;
    left: 0;
    display: block;
}

.house-footer .footer-bottom {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    align-items: end;
    margin-top: 27px;
}

.house-footer .reserved {
    position: relative;
    display: flex;
    gap: 50px;
}

.house-footer .reserved:hover a {
    color: var(--yellow) !important;
}

.house-footer .reserved p {
    font-size: 16px;
    line-height: 26px;
    font-family: var(--indivisible);
}

.house-footer .privacy-policy {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 40px;
    margin-bottom: 0;
}

.house-footer .privacy-policy a {
    font-family: var(--indivisible);
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    text-decoration: underline !important;
}

.house-footer .privacy-policy a:hover {
    color: var(--yellow) !important;
}

.house-footer a.send-message {
    display: block;
    position: relative;
}

.house-footer a.send-message::after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -4px;
    border-bottom: 1px solid var(--dark-green);
    height: 1.5px;
    width: 70%;
}

.house-footer .f-detail .our-story {
    padding-left: 0;
    column-count: 2;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-around;
    align-items: start;
}

.house-footer .f-detail .our-story li {
    margin-bottom: 9px;
}

.house-footer .f-detail ul.our-story a {
    font-size: var(--body-font);
    line-height: var(--body-lign-hight);
    color: var(--dark-green);
    font-family: var(--indivisible);
}

.house-footer .f-detail ul.our-story a:hover {
    color: var(--yellow) !important;
}

.social-links {
    display: flex;
    padding-top: 11px;
    padding-bottom: 13px;
    justify-content: end;
    align-items: center;
}

.social-links li {
    height: 22px;
    width: 22px;
    transition: all 200ms ease-in;
    margin-right: 30px;
}

.social-links li:last-child {
    margin-right: 0px;
}

.social-links li a {
    display: block;
    width: 100%;
    height: 100%;
    transition: all 200ms ease-in;
}

.social-links .facebook-ico a {
    background: url("/public/assets/image/Icon-metro-facebook.png") no-repeat center;
    background-size: contain;
    transition: all 200ms ease-in;
}

.social-links .facebook-ico a:hover {
    background: url("/public/assets/image/Icon-metro-facebook-dark.png") no-repeat center;
    background-size: contain;
}

.social-links .linkedin-ico a {
    background: url("/public/assets/image/Icon-metro-linkedin.png") no-repeat center;
    background-size: contain;
}

.social-links .linkedin-ico a:hover {
    background: url("/public/assets/image/Icon-metro-linkedin-dark.png") no-repeat center;
    background-size: contain;
}

.social-links .x-logo-ico a {
    background: url("/public/assets/image/X_logo_2023_original.png") no-repeat center;
    background-size: contain;
    transition: all 200ms ease-in;
}

.social-links .x-logo-ico a:hover {
    background: url("/public/assets/image/X_logo_2023_original-dark.png") no-repeat center;
    background-size: contain;
}

.social-links .insta-ico a {
    background: url("/public/assets/image/Icon-metro-instagram.png") no-repeat center;
    background-size: contain;
    transition: all 200ms ease-in;
}

.social-links .insta-ico a:hover {
    background: url("/public/assets/image/Icon-metro-instagram-dark.png") no-repeat center;
    background-size: contain;
}

/* footer end */

/* home banner */

.home-hero {
    position: relative;
    width: 84.5%;
    height: auto;
    background: var(--green);
    color: var(--white);
    top: 0;
    left: 0;
    padding-left: 90px;
}

.home-hero .house_container {
    width: 100%;
    overflow: hidden;
}

.home-hero::after {
    position: absolute;
    content: "";
    height: 98px;
    width: 34.5%;
    left: 0;
    bottom: 0;
    z-index: 5;
    background: url("/public/assets/image/bg.png"), var(--bg-color);
    border-radius: 0 10px 0 0;
}

.home-hero .hero-left .sub-title {
    position: relative;
    padding-top: 457px;
}

.home-hero .sub-title .heading-h4 {
    font-weight: 400;
    text-transform: uppercase;
}

.home-hero .hero-left .title {
    position: relative;
    padding: 19px 0 40px;
}

.home-hero .title .heading-h1 {
    font-weight: 400;
    text-transform: uppercase;
    width: 100%;
    max-width: 96%;
}

.home-hero .title .heading-h1 .small {
    font-size: 38px;
    line-height: 55px;
    font-weight: 100 !important;
    font-style: italic;
    text-transform: lowercase;
    position: absolute;
    top: 34px;
    left: 65%;
}

.home-hero .hero-left .disc {
    position: relative;
}

.home-hero .disc p {
    position: relative;
    max-width: 72%;
    font-family: var(--indivisible);
}

.home-hero .disc p .dis-highlight {
    color: var(--yellow);
}

.home-hero .hero-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr 400px);
    position: relative;
    gap: 50px;
}

.home-hero .hero-slider {
    position: relative;
    height: 980px;
}

.home-hero .hero-slider::-webkit-scrollbar {
    width: 0px;
  }
  
.home-hero .hero-slider::-webkit-scrollbar-thumb {
    border-radius: 0px;
  }
  
.home-hero .hero-slider .item:first-child {
    margin-top: 65px;
}

.home-hero .hero-slider .item {
    margin-bottom: 30px;
}

.hero-book-card {
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    height: max-content;
    width: 100%;
    background: var(--green);
}

.hero-book-card .card-image {
    height: 600px;
    width: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
}

.hero-book-card .hero-hover {
    position: absolute;
    top: 0;
    z-index: 2;
    height: 600px;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
    /* -webkit-backdrop-filter: url(/public/assets/image/background-hover.png);
    backdrop-filter: url(/public/assets/image/background-hover.png); */
    background: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(10px) saturate(150%);
    backdrop-filter: blur(10px) saturate(150%);
    display: none;
    transition: all 200ms ease-in;
}

.hero-book-card:hover .hero-hover {
    display: block;
}

.hero-book-card .hero-hover .hover-content {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: max-content;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    gap: 10px;
}

.hero-book-card .hero-hover button {
    font-family: var(--indivisible);
    font-weight: 600;
}

.hero-book-card .info.more-info .info-icon {
    height: 24px;
    width: 24px;
    background: transparent;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 13px;
    background-image: url(/public/assets/image/more-info-v1.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.hero-book-card .info.cart .info-icon {
    height: 21px;
    width: 24px;
    background: transparent;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 18px;
    background-image: url(/public/assets/image/shopping-cart.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.hero-book-card .info.reading .info-icon {
    height: 22px;
    width: 22px;
    background: transparent;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    background-image: url(/public/assets/image/book-group.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.hero-book-card .hero-hover .hover-content .info {
    background: var(--white);
    color: var(--dark-green);
    padding: 10px 17px;
    width: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
}

.hero-book-card .hero-hover .hover-content .info.reading:hover {
    background: var(--dark-green);
    color: var(--white);
}

.hero-book-card .hero-hover .hover-content .info.reading:hover .info-icon{
    background-image: url(/public/assets/image/book-group-yellow.png);
}

.hero-book-card .hero-hover .hover-content .info.more-info:hover {
    background: var(--dark-green);
    color: var(--white);
}

.hero-book-card .hero-hover .hover-content .info.more-info:hover .info-icon{
    background-image: url(/public/assets/image/more-hover-v1.png);
}

.hero-book-card .hero-hover .hover-content .info.cart {
    background: var(--yellow);
}

.hero-book-card .hero-hover .hover-content .info.cart:hover {
    background: var(--dark-green);
    color: var(--white);
}

.hero-book-card .hero-hover .hover-content .info.cart:hover .info-icon{
    background-image: url(/public/assets/image/shopping-cart-yellow.png);
}

.hero-book-card .card-image img {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: auto;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.hero-book-card .card-content {
    position: relative;
    padding: 0 25px;
}

.hero-book-card .sub-title {
    position: relative;
    padding-top: 28px;
    padding-bottom: 5px;
}

.hero-book-card .sub-title p {
    font-size: 14px;
    line-height: 26px;
    font-family: var(--indivisible);
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.hero-book-card .title {
    position: relative;
    padding-bottom: 8px;
    margin-top: 3px;
    max-width: 100%;
    cursor: pointer;
}

.hero-book-card .title p {
    font-size: var(--h3-font-size);
    line-height: var(--h3-line-height);
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

/* hero control button */

.hero-slider .splide__arrow {
    position: relative;
    height: 64px;
    width: 64px;
    background: var(--dark-green);
    border-radius: 55px;
    background-repeat: no-repeat;
    background-position: center center;
    box-shadow: 0px 0px 0px 0px #000, 3px -2px 8px 0px #00000038;
}

.hero-slider .splide__arrows.splide__arrows--ttb {
    position: absolute;
    right: -38px;
    bottom: auto;
    z-index: 2;
    display: grid;
    gap: 20px;
    top: 6.5%;
}

.hero-slider .splide__track .splide__list li {
    height: calc(100% - 60px) !important;
}

.home-hero .hero-slider:hover .splide__arrows.splide__arrows--ttb {
    display: grid;
    gap: 20px;
}

.hero-slider .splide__arrow.splide__arrow--next {
background-image: url(/public/assets/image/arrow-right-white.png);
rotate: 88deg;
}

.hero-slider .splide__arrow.splide__arrow--prev {
background-image: url(/public/assets/image/arrow-left-white.png);
rotate: 88deg;
}

.hero-slider .splide__arrow svg {
    opacity: 0;
}

.hero-slider .splide__arrow:hover {
    background: var(--white);
    box-shadow: 0px 0px 0px 0px #707070f0, 3px 1px 5px 0px #70707057;
}

.hero-slider .splide__arrow.splide__arrow--next:hover {
    background-image: url(/public/assets/image/arrow-right-green.png);
    background-repeat: no-repeat;
    background-position: center center;
}
    
.hero-slider .splide__arrow.splide__arrow--prev:hover {
    background-image: url(/public/assets/image/arrow-left-green.png);
    background-repeat: no-repeat;
    background-position: center center;
}

/* end */

.hero-book-card .detail {
    position: relative;
    padding-bottom: 34px;
}

.hero-book-card .detail p {
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    font-family: var(--indivisible);
    overflow: hidden;
    width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: none;
}

.hero-book-card .detail p:first-child {
    display: -webkit-box;
}

.home-hero .new-to-library::before {
    position: absolute;
    content: "";
    width: 85.5%;
    height: 160px;
    background: #dcb86b;
    bottom: 0;
    left: 0;
    border-radius: 10px 10px 0 0;
}

.home-hero .new-to-library .new-to {
    position: relative;
    z-index: 1;
    display: grid;
    grid-template-columns: 1fr 70px;
    justify-content: end;
    margin-top: 122px;
}

.home-hero .new-to-library .explore {
    position: relative;
    z-index: 1;
    text-align: right;
    width: max-content;
    margin-left: auto;
    margin-right: 68px;
    margin-top: 14px;
}

.home-hero .new-to-library .library {
    text-align: right;
}

.home-hero .new-to-library .blank {
    position: relative;
    display: none;
}

.home-hero .new-to-library .library .heading-h5 {
    color: var(--dark-green);
    text-transform: uppercase;
}

.home-hero .new-to-library .blank::after {
    position: absolute;
    content: " ";
    width: 40px;
    height: 1px;
    background: var(--dark-green);
    left: 30%;
    top: 20px;
}

.home-hero .new-to-library .date-time {
    position: relative;
    display: none;
}

.home-hero .new-to-library .date-time p {
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    font-family: var(--indivisible);
    padding-top: 7px;
    color: var(--dark-green);
}

/* home before footer */
.h-before-footer {
    position: relative;
    padding: 132px 0 128px;
    width: 84.5%;
}

.h-before-footer .house-row {
    max-width: 56%;
    margin: 0 auto;
}

.h-before-footer .house-row .cta {
    text-align: right;
    width: max-content;
    margin-left: auto;
    margin-right: 0px;
}

.h-before-footer .detail {
    position: relative;
    margin-bottom: 25px;
    word-spacing: 2px;
    letter-spacing: 0.5px;
}

.h-before-footer .detail a {
    text-decoration: underline !important;
}

.h-before-footer .detail a:hover {
    color: var(--yellow) !important;
}

.h-before-footer .detail a {
    text-decoration: underline !important;
}

.h-before-footer .detail p {
    font-family: var(--indivisible);
}

.h-before-footer .title .heading-h2 {
    position: relative;
    margin-bottom: 24px;
    font-weight: 600;
}

.h-before-footer .title .heading-h2::after {
    position: relative;
    content: "";
    width: 32%;
    right: -129px;
    bottom: 15px;
    height: 2px;
    background: var(--yellow);
    display: inline-block;
}

.becoming-member {
    padding: 300px 0 100px;
    position: relative;
    width: 84.5%;
}

.becoming-member .house_container {
    width: 100%;
}

.becoming-member .image {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.becoming-member .image img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.becoming-member .content {
    position: relative;
    z-index: 1;
    margin-left: auto;
    max-width: 41%;
    color: var(--white);
}

.becoming-member .content .heading-h2 {
    position: relative;
    padding: 21px 0 28px;
    border-top: 2px solid var(--white);
    border-bottom: 2px solid var(--white);
    margin-bottom: 28px;
    line-height: 54px !important;
}

.becoming-member .content .detail {
    position: relative;
    margin-bottom: 24px !important;
    font-family: var(--indivisible);
}

.picked-titles {
    position: relative;
    padding: 82px 0 108px;
    padding-left: 90px;
    width: 84.5%;
}

.picked-titles .house_container {
    width: 100%;
}

.picked-titles .picked {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.picked-titles .picked-card.dark {
    background: var(--dark-green);
    width: 98%;
}

.picked-titles .picked-card {
    border-radius: 10px;
    position: relative;
    padding: 44px;
    text-align: center;
    background: var(--yellow);
    width: 100%;
    height: 100%;
}

.picked-titles .picked-card .cta {
    padding: 190px 0 0;
    display: flex;
    justify-content: center;
}

.picked-titles .picked-card .picked-title {
    font-size: 140px;
    line-height: 70px;
    font-weight: 400;
    padding-bottom: 30px;
    padding-top: 70px;
}

.picked-titles .picked-card .detail {
    font-size: 38px;
    line-height: 50px;
}

.picked-titles .picked-card.dark img {
    width: 234px;
    display: flex;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


.picked-titles .border-right {
    border-right: 2px dotted var(--yellow);
    border-radius: 13px;
}

.most-in-demand .top-title {
    display: grid;
    grid-template-columns: 18% 56.8% 11.2%;
    gap: 10px;
    padding-left: 90px;
    justify-content: start;
    align-items: end;
}

.most-in-demand .top-title .blank p {
    position: relative;
    width: 100%;
    background: var(--yellow);
    height: 2px;
    margin-bottom: 14px !important;
    margin-left: auto;
}

.most-in-demand .top-title .title {
    margin-bottom: -9px;
    font-weight: 600;
    line-height: 52px;
}

.most-in-demand .top-title .cta .cost-btn {
    margin-left: auto;
    margin-bottom: 2px;
}

.most-in-demand .top-title .cta {
    position: relative;
    text-align: right;
}

.demand-card {
    position: relative;
}

.demand-card .card-image {
    width: 400px;
    height: 600px;
    border-radius: 15px;
    padding: 0;
    margin: 0;
}

.demand-card .card-image img {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: auto;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

.demand-card .number {
    position: absolute;
    left: 55px;
    top: 243px;
    width: max-content;
    z-index: 0;
}

.demand-card .number p {
    -webkit-text-stroke: 2px var(--yellow);
    color: transparent;
    font-size: 400px;
}

.demand-card .content {
    max-width: 400px;
    margin-left: auto;
    position: relative;
    z-index: 2;
}

.demand-slider .splide__arrows.splide__arrows--ltr {
    position: absolute;
    right: 0;
    top: -103px;
    gap: 10px;
    display: inline-flex;
}

.demand-slider .splide__arrow {
    position: relative;
    height: 64px;
    width: 64px;
    background: var(--dark-green);
    border-radius: 55px;
    border: 1.5px solid var(--white);
    background-repeat: no-repeat;
    background-position: center center;
}

.demand-slider .splide__arrow.splide__arrow--next {
background-image: url(/public/assets/image/arrow-right-white.png);
}

.demand-slider .splide__arrow.splide__arrow--prev {
background-image: url(/public/assets/image/arrow-left-white.png);
}

.demand-slider .splide__arrow svg {
    opacity: 0;
}

.demand-slider .splide__arrow:hover {
    background: var(--white);
    box-shadow: 0px 0px 0px 0px #707070f0, 3px 1px 5px 0px #70707057;
}

.demand-slider .splide__arrow.splide__arrow--next:hover {
    background-image: url(/public/assets/image/arrow-right-green.png);
    background-repeat: no-repeat;
    background-position: center center;
}
    
.demand-slider .splide__arrow.splide__arrow--prev:hover {
    background-image: url(/public/assets/image/arrow-left-green.png);
    background-repeat: no-repeat;
    background-position: center center;
}

.demand-slider {
    position: relative;
    margin-top: 56px;
}

.most-in-demand {
    position: relative;
    padding-top: 53px;
}

.most-in-demand.repeat.fiction {
    margin-top: 70px;
    margin-bottom: 50px;
}

.most-in-demand.repeat .demand-slider {
    padding-left: 90px;
}

.demand-card .content .detail {
    padding: 37px 20px;
}

.demand-card .content .detail .more-info {
    font-family: var(--indivisible);
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.demand-card .content .detail .book-name {
    padding: 5px 0 7px;
    margin-bottom: 0;
    text-transform: uppercase;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
}

.demand-card .content .detail .category {
    font-size: 14px;
    line-height: 26px;
    font-weight: 600;
    font-family: var(--indivisible);
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}


/* function card */
.fiction-slide-card {
    position: relative;
    padding-top: 7px;
}

.fiction-slide-card .card-image {
    width: 100%;
    height: 600px;
    border-radius: 15px;
    padding: 0;
    margin: 0;
    box-shadow: var(--dark-green) 0px 1px 9px 0px;
}

.fiction-slide-card .card-image img {
    bottom: 0;
    height: 100%;
    left: 0;
    margin: auto;
    object-fit: cover;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
}

/* fiction card-hover */

.fiction-slide-card .hero-hover {
    position: absolute;
    top: 7px;
    z-index: 2;
    height: 600px;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
    /* -webkit-backdrop-filter: url(/public/assets/image/background-hover.png) blur(10px) saturate(150%);
    backdrop-filter: url(/public/assets/image/background-hover.png) blur(10px) saturate(150%); */
    background: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(10px) saturate(150%);
    backdrop-filter: blur(10px) saturate(150%);
    display: none;
    transition: all 200ms ease-in;
}

.fiction-slide-card:hover .hero-hover {
    display: block;
}

.fiction-slide-card .hero-hover .hover-content {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: max-content;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    gap: 10px;
}

.fiction-slide-card .hero-hover button {
    font-family: var(--indivisible);
    font-weight: 600;
}

.fiction-slide-card .info.more-info .info-icon {
    height: 24px;
    width: 24px;
    background: transparent;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 13px;
    background-image: url(/public/assets/image/more-info-v1.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.fiction-slide-card .info.cart .info-icon {
    height: 21px;
    width: 24px;
    background: transparent;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 18px;
    background-image: url(/public/assets/image/shopping-cart.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.fiction-slide-card .info.reading .info-icon {
    height: 22px;
    width: 22px;
    background: transparent;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    background-image: url(/public/assets/image/book-group.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.fiction-slide-card .hero-hover .hover-content .info {
    background: var(--white);
    color: var(--dark-green);
    padding: 10px 17px;
    width: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
}

.fiction-slide-card .hero-hover .hover-content .info.reading:hover {
    background: var(--dark-green);
    color: var(--white);
}

.fiction-slide-card .hero-hover .hover-content .info.reading:hover .info-icon{
    background-image: url(/public/assets/image/book-group-yellow.png);
}

.fiction-slide-card .hero-hover .hover-content .info.more-info:hover {
    background: var(--dark-green);
    color: var(--white);
}

.fiction-slide-card .hero-hover .hover-content .info.more-info:hover .info-icon{
    background-image: url(/public/assets/image/more-hover-v1.png);
}

.fiction-slide-card .hero-hover .hover-content .info.cart {
    background: var(--yellow);
}

.fiction-slide-card .hero-hover .hover-content .info.cart:hover {
    background: var(--dark-green);
    color: var(--white);
}

.fiction-slide-card .hero-hover .hover-content .info.cart:hover .info-icon{
    background-image: url(/public/assets/image/shopping-cart-yellow.png);
}

/* card hover end */

.fiction-slide-card .detail {
    padding: 37px 20px;
}

.fiction-slide-card .detail .book-name {
    padding: 7px 0;
    margin-bottom: 0;
    text-transform: uppercase;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* word-wrap: break-word;
    line-break: anywhere; */
    cursor: pointer;
}


.fiction-slide-card .detail .more-info p {
    display: none;
} 

.fiction-slide-card .detail .more-info p:first-child {
    display: block;
}  

.fiction-slide-card .detail .more-info {
    font-family: var(--indivisible);
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.fiction-slide-card .detail .category {
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
    font-family: var(--indivisible);
    text-transform: uppercase;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.most-in-demand.repeat .top-title {
    display: grid;
    grid-template-columns: 20% 53.8% 11.2%;
    gap: 10px;
    padding-left: 90px;
    justify-content: start;
    align-items: end;
}

.most-in-demand.repeat.fiction .top-title {
    grid-template-columns: 12% 61.8% 11.2%;
    gap: 10px;
}

.most-in-demand.repeat.fiction .top-title .blank p {
    width: 99%;
    height: 2px;
    margin-bottom: 14px !important;
}

/* login register */
.sign-in-form-wrap {
    position: relative;
    width: 84.5%;
}

.login-main-wrap {
    padding-top: 294px;
    max-width: 610px;
    margin: 0 auto;
    text-align: center;
}

.login-main-wrap .login-subtitle {
    text-align: center;
    text-transform: uppercase;
    color: var(--green);
    margin-bottom: 11px;
}

.sign-form-main {
    max-width: 560px;
    margin: 0 auto;
    padding-bottom: 120px;
}

.sign-form-main.sign {
    max-width: 842px;
}

.form-control {
    border-bottom: 1px solid var(--green);
    width: 300px;
    border-radius: 0;
}

.sign-form-main .submit-cta .t-btn {
    margin-left: auto;
}

.login-form-main .pass-Forgot {
    position: relative;
    padding: 26px 0 20px;
    text-align: right;
}

.sign-in-form-wrap .have-account {
    text-align: right;
    margin-bottom: 20px;
  }

.sign-in-form-wrap .login-title {
    margin-bottom: 40px;
}

.sign-in-form-wrap .login-title.sign-title {
    text-align: center;
}

.login-form-main .cost-form-input {
    margin-bottom: 0px;
}

.login-form-main .submit-cta {
    padding-top: 4px;
}

.login-form-main .pass-Forgot .cost-a.t-btn {
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);
    font-family: var(--indivisible);
    text-decoration: underline;
    margin-left: auto;
    padding-right: 0;
}

.login-form-main .pass-Forgot .cost-a.t-btn span {
    display: none;
}

.sign-in-form-wrap .have-account p {
    display: inline-flex;
}

.sign-in-form-wrap .have-account p .cost-a.t-btn {
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);
    text-decoration: underline;
    margin-left: auto;
    padding-right: 0;
    padding-left: 3px;
}

.sign-in-form-wrap .have-account p {
    font-size: var(--h6-font-size);
    line-height: var(--h6-line-height);
    font-family: var(--indivisible);
}

.sign-in-form-wrap .have-account p .cost-a.t-btn  span {
    display: none;
}

.sign-form-main.sign .sign-form-col {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.sign-form-main.sign .cost-form-input.w-80 {
    width: 62.5%;
}

.sign-form-main.sign .cost-form-input.w-45 {
    width: 35%;
}

.sign-form-main.sign .cost-form-input.w-55 {
    width: 45%;
}

.sign-form-main.sign .col-sp {
    grid-column: 1 / span 2;
}

.sign-form-main.sign .have-account.sign-ragi {
    margin-top: 24px;
}

.login-main-wrap.forgot {
    max-width: 650px;
    text-align: center;
}

/* library overview */
.library-overview-hero {
    position: relative;
    width: 84.5%;
    padding-left: 90px;
    background: var(--green);
}

.library-overview-hero .house_container {
    width: 100%;
}

.library-overview-hero .overview-subtitle {
    text-align: center;
    text-transform: uppercase;
    color: var(--white);
    padding-top: 297px;
    margin-bottom: 0;
}

.library-overview-hero .overview-title {
    position: relative;
    text-align: center;
    color: var(--white);
    margin-top: 25px;
    text-transform: uppercase;
    font-weight: 400;
    overflow: hidden;
    width: 100%;
}

.library-overview-hero .overview-detail {
    text-align: center;
    color: var(--yellow);
    text-transform: uppercase;
    margin-top: 26px;
    padding-bottom: 150px;
}

.library-overview-hero::after {
    position: absolute;
    content: "";
    height: 98px;
    width: 95%;
    left: 0;
    bottom: 0;
    background: url(/public/assets/image/bg.png), var(--bg-color);
    border-radius: 0 10px 0 0;
}

.library-overview-hero.detail::after {
    width: 40.5%;
}

.library-overview-hero.detail .book-detail-con {
    padding-top: 290px;
    display: grid;
    grid-template-columns: 46% 54%;
}

.library-overview-hero.detail .book-detail-con .left {
    padding-top: 32px;
}

.library-overview-hero.detail .overview-detail {
    text-align: start;
    padding-bottom: 32px;
    color: var(--white);
    margin-top: 18px;
}

.library-overview-hero.detail .login-title {
    text-align: start;
}

.library-overview-hero.detail .overview-subtitle {
    text-align: start;
}

.you-like-title {
    position: relative;
    width: 84.5%;
    padding-left: 90px;
    margin-top: 140px;
    margin-bottom: 49px;
}

.you-like-title::after {
    position: absolute;
    content: " ";
    width: 64%;
    height: 2px;
    background: var(--yellow);
    right: 90px;
    top: 25px;
}

.library-overview-hero.detail .reviews {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
    padding-bottom: 0px;
    color: var(--white);
    margin-top: 0px;
}

.library-overview-hero.detail-v1 .right {
    margin-bottom: 70px;
}

.library-overview-hero.detail .reviews .google-reviews {
    padding-bottom: 6px;
}

.library-overview-hero.detail .reviews .total p {
    font-size: 16px;
    font-family: var(--indivisible);
}

.overview-card-row {
    grid-gap: 40px;
    display: grid;
    gap: 40px;
    grid-template-columns: repeat(4, 23%);
    margin-top: 187px;
    padding-left: 90px;
    padding-right: 90px;
    position: relative;
    width: 84.5%;
}

.overview-card-row .house_container {
    width: 100%;
    padding-right: 90px;
}

.overview-card-row .fiction-slide-card .card-image {
    height: 493px !important;
    width: 328.44px !important;;
}

.overview-card-row .fiction-slide-card .hero-hover {
    height: 497px !important;
    width: 100%;
}

.overview-footer {
    position: relative;
    width: 84.5%;
    padding: 50px 90px 100px;
}

.overview-footer .load-row {
    display: grid;
    grid-template-columns: 53% 47%;
}

.overview-footer .load-more button {
    margin-left: auto;
}

.overview-footer .go-to-top button {
    margin-left: auto;
}

.overview-footer .load-more button span {
    display: none;
}

.overview-footer .load-more button.t-btn::after {
    width: 100% !important;
}

.overview-footer .go-to-top button span {
    transform: rotate(-90deg);
}

.overview-filter-main {
    width: 84.5%;
    padding: 20px 90px 10px;
    margin-top: -60px;
    position: absolute;
    z-index: 3;
}

.overview-filter-main .overview-top-title {
    display: grid;
    grid-template-columns: 77% 19%;
    gap: 4%;
}

.overview-filter-main .overview-top-title  .heading-h5 {
    text-transform: uppercase;
}

.overview-filter-main .filter-row {
    display: grid;
    justify-content: center;
    align-items: start;
    grid-template-columns: 77% 19%;
    gap: 4%;
}

.overview-filter-main .filter-row .left-area {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 22px;
}


.custom-dropdown .dropdown-options {
    max-height: 300px;
    overflow: auto;
    background: var(--bg-color);
    z-index: 999;
    position: relative;
}

.custom-dropdown .dropdown-options::-webkit-scrollbar {
    width: 5px;
}

/* custom dropdown start */
.custom-dropdown {
    position: relative;
    border: 1px solid var(--green);
    border-radius: 10px;
    width: 100%;
    cursor: pointer;
    transition: all 0.3s ease-out 0s !important;
    background: url('/public/assets/image/bg.png'), var(--bg-color);
    overflow: hidden;
}

.custom-dropdown::after {
    position: absolute;
    content: "";
    right: 15px;
    top: 74px;
    width: 11px;
    height: 8px;
    background-image: url('/public/assets/image/arrow-down-dropdown.svg');
    background-repeat: no-repeat;
    background-position: top left;
}

.custom-dropdown.open::after {
    background-image: url('/public/assets/image/arrow-up-dropdown.svg');
}

.custom-dropdown .dropdown-options .option {
    font-size: 14px;
    line-height: 14px;
    font-family: var(--indivisible);
    padding-top: 6px;
    padding: 5px 15px;
}

.custom-dropdown .dropdown-options .option:hover {
    background: var(--dark-green);
    color: var(--white);
}

.custom-dropdown .selected-option {
    padding: 62px 20px 20px;
    font-size: 18px;
    line-height: 26px;
    font-family: var(--indivisible);
    position: relative;
}

.custom-dropdown.open .selected-option {
    padding-bottom: 0;
}

.custom-dropdown .dropdown-options .option:last-child {
    padding-bottom: 20px;
}

.selected-option.category::after {
    position: absolute;
    content: "";
    height: 33px;
    width: 33px;
    background-image: url('/public/assets/image/category-icon.png');
    background-repeat: no-repeat;
    background-position: top left;
    top: 20px;
    left: 20px;
}

.selected-option.genre::after {
    position: absolute;
    content: "";
    height: 33px;
    width: 33px;
    background-image: url('/public/assets/image/genre-icon.png');
    background-repeat: no-repeat;
    background-position: top left;
    top: 20px;
    left: 20px;
}


.selected-option.publisher::after {
    position: absolute;
    content: "";
    height: 33px;
    width: 33px;
    background-image: url('/public/assets/image/publisher-icon.png');
    background-repeat: no-repeat;
    background-position: top left;
    top: 20px;
    left: 20px;
}

.selected-option.author::after {
    position: absolute;
    content: "";
    height: 33px;
    width: 33px;
    background-image: url('/public/assets/image/author-icon.png');
    background-repeat: no-repeat;
    background-position: top left;
    top: 20px;
    left: 20px;
}

.selected-option.popular::after {
    position: absolute;
    content: "";
    height: 33px;
    width: 33px;
    background-image: url('/public/assets/image/popular-icon.png');
    background-repeat: no-repeat;
    background-position: top left;
    top: 20px;
    left: 20px;
}

.product-detail {
    position: relative;
    display: grid;
    grid-template-columns: 46% 54%;
    padding: 0 90px;
    width: 84.5%;
}

.product-detail .product {
    position: relative;
    width: 474px;
    height: 707px;
    overflow: hidden;
    border-radius: 10px;
}

.product-detail .product .google-reviews {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.product-detail .book-category {
    display: flex;
    margin-top: 56px;
    margin-bottom: 15px;
}

.product-detail .category-list ul {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    justify-content: start;
    position: relative;
    gap: 23px;
    list-style-image: url('/public/assets/image/detail-list-icon.png');
    margin-left: -18px;
}

.product-detail .casualties ul {
    list-style-image: none;
}

.product-detail .casualties ul li::before {
    content: " ";
    position: absolute;
    background-image: url('/public/assets/image/detail-list-icon.png');
    left: -15px;
    top: 11px;
    height: 8px;
    width: 8px;
    background-size: cover;
}

.product-detail .casualties ul li:first-child:before {
    display: none;
}

.product-detail .category-list ul li {
    position: relative;
}

.product-detail .add-to-cart {
    background: var(--yellow);
    color: var(--dark-green);
    padding: 13px 55px;
    margin-bottom: 20px;
}

.product-detail .add-to-cart:hover {
    background: var(--dark-green);
    padding: 13px 70px;
    color: var(--white);
}

.product-detail .add-cart-cta {
    grid-gap: 30px;
    display: grid;
    gap: 30px;
    grid-template-columns: 330px 1fr;
    margin-bottom: 5px;
}

.product-detail .add-to-cart span {
    margin-right: 15px !important;
    width: 24px;
    height: 21px;
    background-image: url('/public/assets/image/shopping-cart.png');
    background-size: cover;
}

.product-detail .add-to-cart:hover span {
    background-image: url('/public/assets/image/shopping-cart-yellow.png');
}

.product-detail .notify-btn {
    background: transparent;
    border: 1.5px solid var(--notify);
    color: var(--notify);
    padding: 13px 55px;
    margin-bottom: 20px;
    /* display: none; */
}

.product-detail .notify-btn:hover {
    background: var(--notify);
    color: var(--white);
}

.product-detail .reading-cart {
    background: transparent;
    border: 1.5px solid var(--dark-green);
    color: var(--dark-green);
    padding: 12px 55px;
}

.product-detail .reading-cart span {
    margin-right: 15px !important;
    width: 22px;
    height: 22px;
    background-image: url('/public/assets/image/book-group.png');
    background-size: cover;
}

.product-detail .reading-cart:hover {
    background: var(--dark-green);
    border: 1.5px solid var(--dark-green);
    color: var(--yellow);
}

.product-detail .reading-cart:hover span {
    background-image: url('/public/assets/image/book-group-yellow.png');
    background-size: cover;
}

.product-detail .day-delivery .in-stock {
    position: relative;
    padding-left: 23px;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 75px !important;
}

.product-detail .day-delivery .in-stock::after {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    background-image: url('/public/assets/image/instock-icon.png');
    background-size: cover;
    left: 0;
    top: 6px;
}

.product-detail .day-delivery .out-stock {
    color: var(--notify);
    position: relative;
    padding-left: 23px;
    font-size: 16px;
    font-weight: 400;
}

.product-detail .day-delivery .out-stock::after {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    background-image: url('/public/assets/image/notify-icon.png');
    background-size: cover;
    left: 0;
    top: 6px;
}

.product-detail  .casualties {
    display: flex;
    margin-top: 20px;
}

.product-detail .book-detail {
    position: relative;
    padding: 21px 0 59px;
    border-top: 1.5px solid var(--dark-green);
    border-bottom: 1.5px solid var(--dark-green);
    margin-top: 20px;
}

.product-detail .book-detail .detail-title {
    font-size: 38px;
    line-height: 44px;
    font-weight: 500;
    margin-bottom: 20px !important;
}

.product-detail .book-detail p {
    margin-bottom: 20px !important;
}

.product-detail .book-detail.bt-0 {
    border-top: 0;
    margin-top: 0px !important;
}

.library-overview-hero.detail .left.cost-back span {
    position: absolute;
    left: 0;
    background: url("/public/assets/image/arrow-left-white.png") no-repeat center;
}

.library-overview-hero.detail .left.cost-back span:after {
    right: 0 !important;
}

.library-overview-hero.detail .header-btn-y {
    padding-left: 32px;
}

.library-overview-hero.detail .book-detail-con .left .t-btn::after {
    bottom: 0;
    right: 0;
    left: auto;
}

.overview-card-row.detail {
    margin-bottom: 110px;
}

.library-overview-hero.join-us .overview-title {
    padding-top: 25px;
    max-width: 71%;
    margin: 0 auto;
}

/* common radio button */
.common-radio .form-check.active {
    border-color: #421f58;
}

.common-radio .form-check {
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: 0.5s;
    position: relative;
}

.common-radio .form-check-input:checked[type=radio] {
    background-image: url("/public/assets/image/radio-bg.png") !important;
    background-size: inherit;
    background-repeat: no-repeat;
    background-position: center center;
}

.common-radio .form-check-input:checked {
    background-color: var(--dark-green);
    border-color: var(--dark-green);
}

.common-radio .select-radio input {
    position: absolute;
    top: -5px;
    left: 28px;
    height: 22px;
    width: 22px;
    border: 1.5px solid var(--dark-green);
}

.common-radio .select-radio .form-check-input:checked {
    background-color: transparent;
    border-color: var(--dark-green);
}

.common-radio .select-radio label {
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    max-width: 78%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 10px;
    font-family: var(--indivisible);
}

.common-radio .select-radio .form-check-input:focus {
    border-color: var(--dark-green);
    outline: 0;
    box-shadow: none;
}

.choose-plan-main {
    position: relative;
    width: 84.5%;
    padding: 0 90px;
    margin-top: -240px;
    margin-bottom: 100px;
}

.choose-plan-main .plan-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 50px;
    row-gap: 80px;
    width: 80%;
    margin: 0 auto;
}

.plan-card {
    position: relative;
    border: 2px solid var(--yellow);
    padding: 40px 65px 55px;
    border-radius: 10px;
}

.plan-card .choose-plan .heading-h5 {
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 18px;
}

.plan-card .plan-detail {
    display: grid;
    grid-template-columns: 45% 55%;
    margin-bottom: 9px;
}

.plan-card .card-title {
    text-transform: uppercase;
    font-weight: 600;
}

.plan-card .plan-detail .plan-price {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.plan-card .plan-price .old-price {
    font-size: 14px;
    text-decoration: line-through;
    line-height: 26px;
    font-weight: 500;
    font-family: var(--indivisible);
    text-align: right;
}

.plan-card .plan-price .new-price {
    font-weight: 400;
    text-align: right;
    font-family: var(--indivisible);
}

.s-mobile {
    display: none;
}

.plan-card .card-detail {
    position: relative;
    margin-left: 0px;
    font-family: var(--indivisible);
    border-bottom: 2px solid var(--dark-green);
    padding-bottom: 18px;
    margin-top: 19px;
    margin-bottom: 32px;
}

.plan-card .card-detail li {
    position: relative;
    margin-bottom: 8px;
}

.plan-card .card-detail li:after {
    position: absolute;
    content: "";
    height: 21px;
    width: 21px;
    background-image: url('/public/assets/image/plan-list.png');
    background-size: cover;
    left: -31px;
    top: 1px;
}

.plan-card .plan-cta {
    position: absolute;
    bottom: -27px;
    width: -webkit-fill-available;
    padding-right: 65px;
}

.plan-card .plan-cta button {
    margin: 0 auto;
    width: max-content;
}

.library-overview-hero.join-us::after {
    width: 94.5%;
    height: 306px;
}

.library-overview-hero.join-us .overview-detail {
    margin-top: 26px;
    padding-bottom: 386px;
}

.join-questions-main {
    width: 84.5%;
    padding: 50px 90px;
    position: relative;
}

.join-questions-main .faq-row {
    display: grid;
    grid-template-columns: 32% 67%;
    gap: 1%;
}

/* faq */
.faq-accordion .accordion-item {
    color: var(--dark-green);
    background-color: transparent !important;
    border: 2px dotted var(--yellow);
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
}

.faq-accordion .accordion-item.item-show {
    border: 2px dotted var(--dark-green) !important;
}

.faq-accordion .accordion-item:not(:first-of-type) {
    border-top: 2px dotted var(--yellow) !important;
}

.faq-accordion .accordion-item.item-show:not(:first-of-type) {
    border-top: 2px dotted var(--dark-green) !important;
}

.accordion-item.item-show .accordion-item:not(:first-of-type) {
    border-top: 2px dotted var(--dark-green) !important;
}

.faq-accordion .accordion-button:not(.collapsed) {
    box-shadow: none;
    padding-bottom: 0 !important;
}

.faq-accordion .accordion-button {
    background: transparent;
}

.faq-accordion .accordion-item:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.faq-accordion .accordion-item:last-of-type {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.faq-accordion .accordion-button:not(.collapsed)::after {
    background-image:  url('/public/assets/image/arrow-green-bg.png');
    transform: rotate(-90deg);
}

.faq-accordion .accordion-button::after {
    flex-shrink: 0;
    width: 18px;
    height: 12px;
    margin-left: auto;
    content: "";
    background-image:  url('/public/assets/image/arrow-green-bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    transform: rotate(90deg);
    margin-top: 10px;
}

.faq-accordion .accordion-button {
    position: relative;
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 40px 90px;
    font-size: 22px;
    line-height: 34px;
    color: var(--dark-green) !important;
    text-align: left;
    background-color: transparent !important;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    font-weight: 600;
    gap: 10px;
    transition: 0.5s;
}

.faq-accordion .accordion-body {
    padding: 12px 90px 36px;
}

.faq-accordion .accordion-button:focus {
    z-index: 3;
    border-color: transparent !important;
    outline: 0;
    box-shadow: none !important;
}

.join-questions-main .q-card {
    position: relative;
    height: 400px;
    width: 100%;
    background: var(--dark-green);
    border-radius: 10px;
    margin-top: 10px;
}

.join-questions-main .q-card p {
    font-size: 38px;
    line-height: 44px;
    color: var(--white);
    max-width: 44%;
    padding: 130px 0 50px 90px;
}

.join-questions-main .faq-row .q-card-cta {
    margin-top: 37px;
    width: 100%;
}

.join-questions-main .faq-row .q-card-cta button {
    margin: 0 auto;
}

.contact-us-main {
    width: 84.5%;
    padding: 0 90px;
    position: relative;
    margin-top: -239px;
}

.contact-us-main .contact-row {
    display: grid;
    grid-template-columns: 34.5% auto;
    gap: 6.5%;
    margin-bottom: 160px;
}

.contact-us-main .left {
    position: relative;
}

.contact-us-main .left .inquiries {
    margin-bottom: 29px;
}

.contact-us-main .right {
    position: relative;
    padding-right: 138px;
}

.contact-us-main .left .address {
    background: var(--dark-green);
    border-radius: 10px;
    padding: 40px;
}

.contact-us-main .left .address a {
    display: flex;
    gap: 11px;
}

.contact-us-main .left .address .cta {
    color: var(--yellow) !important;
    font-size: 22px;
    line-height: 34px;
    font-weight: 400;
    margin-bottom: 5px;
}

.contact-us-main .left .address .tel.cta {
    padding-left: 4px;
}

.contact-us-main .left .address .location.cta {
    padding-left: 0px;
    font-size: 18px;
    line-height: 26px;
    color: var(--white) !important;
    margin-top: 28px;
}

.contact-us-main .left .address {
    background: var(--dark-green);
    border-radius: 10px;
    padding: 62px 30px 56px;
}

.contact-us-main .left .address .cta span {
    margin-right: 10px;
}

.contact-us-main .in-touch {
    position: relative;
}

.contact-us-main .in-touch .sub-title {
    color: var(--yellow);
    text-transform: uppercase;
    margin-bottom: 42px;
}

.contact-us-main .in-touch .title {
    margin-bottom: 43px;
}

.library-overview-hero.contact-us::after {
    height: 305px;
    width: 95%;
    left: 0;
    bottom: 0;
}

.contact-us-main .in-touch .sub-title {
    color: var(--yellow);
    text-transform: uppercase;
    margin-bottom: 29px;
    margin-top: 6px;
}

.library-overview-hero.contact-us .overview-detail {
    padding-bottom: 383px;
}

.contact-form .sign-form-col {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
}

.contact-form .sign-form-col .w-45 {
    width: 45%;
}

.contact-form .sign-form-col .w-55 {
    width: 53%;
}

.contact-form .login-form-main .cost-form-input {
    margin-bottom: 17px;
}

.contact-form .login-form-main .submit-cta {
    padding-top: 4px;
}

.contact-form .login-form-main .submit-cta button {
    margin-left: auto;
}

.contact-form .cost-form-input.comments .cost-form-group {
    border-bottom: 0;
    display: block;
    margin-bottom: 10px;
}

.contact-form .no-grid .cost-form-group {
    grid-template-columns: 1fr;
}

.contact-form .cost-form-group {
    grid-template-columns: max-content max-content;
}

.contact-form .cost-form-group label.form-label {
    margin-bottom: 15px;
    margin-right: 15px;
}

.join-questions-main.faq {
    margin-top: -295px;
    padding-bottom: 143px;
}

.join-questions-main.faq .right {
    width: 80%;
    margin: 0 auto;
}

.library-overview-hero.faq .overview-title {
    padding-bottom: 446px;
}

.library-overview-hero.faq::after {
    width: 94.5%;
    height: 306px;
}

.library-overview-hero.terms .overview-subtitle {
    padding-top: 297px;
    padding-bottom: 442px;
    width: 50%;
    margin: 0 auto;
}

.library-overview-hero.terms::after {
    width: 94.5%;
    height: 306px;
}

.t-and-c-main {
    position: relative;
    margin-top: -219px;
    display: grid;
    grid-template-columns: 26.5% 67%;
    width: 84.5%;
    padding: 0 90px 125px;
    gap: 6.5%;
}

.t-and-c-main .left-main ul {
    list-style: auto;
}

.t-and-c-main .left-main a {
    text-decoration: underline !important;
    cursor: pointer;
    font-family: var(--indivisible);
}

.t-and-c-main .left-main {
    padding: 40px;
    border: 1.5px dotted var(--dark-green);
    border-radius: 10px;
    position: sticky;
    top: 120px;
}

.t-and-c-main .right-content {
    padding-right: 150px;
}

.t-and-c-main .right-content .title {
    padding-top: 31px;
}

.t-and-c-main .right-content .title.mt-0 {
    padding-top: 0px;
}

.t-and-c-main .right-content .detail  {
    font-family: var(--indivisible);
} 

.t-and-c-main .right-content .detail ul {
    list-style: auto;
}

.sign-form-main.very-fy-mail .cost-form-group {
    grid-template-columns: 1fr;
    margin-bottom: 10px;
}

.sign-form-main.very-fy-mail .submit-cta {
    padding-top: 25px;
    margin-bottom: 50px;
}

.thanku-page-main {
    position: relative;
    width: 50%;
    margin: 0 auto;
    padding-top: 360px;
    text-align: center;
    height: 100vh;
}

.thanku-page-main .thanku-subtile {
    color: var(--yellow);
}

.thanku-page-main .cta {
    position: relative;
    width: 100%;
    margin-top: 26px;
    margin-bottom: 250px;
}

.thanku-page-main .cta button {
    margin: 0 auto
}

.thanku-page-main .icon-thumb {
    position: relative;
    width: 44px;
    height: 44.71px;
    margin: 0 auto;
    overflow: hidden;
}

.thanku-page-main .icon-thumb img {
    background-size: cover;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.thanku-page-main .thanku-title {
    font-weight: 600;
    margin-top: 26px;
    margin-bottom: 7px;
}

.thanku-page-main .thanku-title span {
    display: block;
    padding-bottom: 8px;
}

.page-not-found {
    position: relative;
    width: 84.5%;
}

.our-story-main {
    position: relative;
    background: var(--green);
    width: 84.5%;
    padding: 392px 90px 0;
}

.our-story-main::after {
    position: absolute;
    content: " ";
    height: 98px;
    width: 38.5%;
    left: 0;
    bottom: 0;
    background: url(/public/assets/image/bg.png), var(--bg-color);
    border-radius: 0 10px 0 0;
}

.our-story-main .story-title {
    color: var(--white);
    text-transform: uppercase;
    width: 70%;
    padding-top: 23px;
    font-weight: 400;
    text-align: center;
    margin: 0 auto;
}

.our-story-main .story-subtitle {
    color: var(--white);
    text-transform: uppercase;
    text-align: center;
}

.our-story-main .blank-bottom {
    height: 160px;
    width: 86%;
    background: var(--yellow);
    border-radius: 10px 10px 0 0;
    margin-top: 72px;
}

.our-story-s1 .r-image .img-1 {
    width: 440px;
    height: 540px;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
}

.our-story-s1 .r-image .img-1 img {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.our-story-s1 {
    position: relative;
    width: 84.5%;
    padding: 0 90px;
}

.our-story-s1 .s1-row {
    display: grid;
    grid-template-columns: 31% 62.7%;
    gap: 6.3%;
}

.our-story-s1 .s1-row .right {
    position: relative;
}

.our-story-s1 .s1-row .right .r-image {
    margin-top: 115px;
    display: flex;
    gap: 20px;
}

.our-story-s1 .s1-left {
    position: relative;
    padding-top: 108px;
}

.our-story-s1 .s1-left .s-title {
    max-width: 80%;
    margin-bottom: 20px;
}

.our-story-s1 .s1-left .s-content {
    position: relative;
    padding-bottom: 15px;
}

.our-story-s1 .s1-left p {
    text-align: justify;
}

.our-story-s2 {
    position: relative;
    width: 84.5%;
    padding: 150px 90px 50px;
}

.our-story-s2 .left-s2 .image1 {
    height: 380px;
    width: 380px;
    overflow: hidden;
    border-radius: 15px;
    margin-top: 38px;
}

.our-story-s2 .left-s2 .image1 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.our-story-s2 .left-s2 .image2 {
    height: 190px;
    width: 190px;
    overflow: hidden;
    border-radius: 15px;
    margin-left: auto;
    margin-top: 22px;
}

.our-story-s2 .left-s2 .image2 img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.our-story-s2 .row-s2 {
    display: grid;
    grid-template-columns: 26.5% 66%;
    margin-top: 45px;
    gap: 7.5%;
}

.our-story-s2 .top-main {
    width: 78%;
    margin-left: auto;
    padding-right: 17%;
}

.our-story-s2 .row-s2 .right-s2 {
    padding-right: 28%;
    text-align: justify;
}

.our-story-s2 .row-s2 .right-s2 .s2-right {
    height: 200px;
    text-align: justify;
}

.our-story-s2 .row-s2 .right-s2 .s2-content {
    padding-bottom: 8px;
}

.my-account-hero {
    position: relative;
    width: 84.5%;
    padding-left: 90px;
}

.my-account-hero .ac-sub-title {
    color: var(--yellow);
    text-align: center;
    padding-top: 301px;
    margin-bottom: 35px;
    text-transform: uppercase;
    font-weight: 600;
    padding-right: 90px;
}

.my-account-hero .ac-title {
    text-transform: uppercase;
    text-align: center;
    font-size: var(--login-title);
    line-height: var(--login-line-height);
    max-width: 55%;
    margin: 0 auto;
    padding-bottom: 47px;
}

.subscription-main {
    position: relative;
    width: 84.5%;
    padding: 0 90px;
}

.subscription-main .nav-link {
    border: 1px solid var(--green) !important;
    font-family: var(--indivisible);
    color: var(--green);
    border-radius: 10px !important;
    position: relative;
    height: 100px;
    text-align: left;
    font-size: 18px;
    padding: 60px 20px 15px;
}

.subscription-main .cost-tab {
    display: grid;
    grid-template-columns: repeat(auto-fill, 180px);
    width: 85%;
    margin: 0 auto;
    gap: 15px;
    justify-content: center;
    align-items: center;
    border: 0px;
}

.subscription-main .nav-link.active {
    background: var(--green) !important;
    color: var(--yellow) !important;
}

.subscription-main .nav-link:focus, 
.subscription-main .nav-link:hover {
    background: var(--green) !important;
    color: var(--yellow) !important;
}

#SubscriptionMain-tab-Subscription::after {
    position: absolute;
    content: " ";
    height: 27.5px;
    width: 32px;
    left: 20px;
    top: 20px;
    background: url("/public/assets/image/subscription.png") no-repeat center;
    background-size: contain;
}

#SubscriptionMain-tab-Subscription.active::after {
    background: url("/public/assets/image/subscription-active.png") no-repeat center;
    background-size: contain;
}

#SubscriptionMain-tab-Subscription:focus::after, 
#SubscriptionMain-tab-Subscription:hover::after {
    background: url("/public/assets/image/subscription-active.png") no-repeat center;
    background-size: contain;
}

/*  */
#SubscriptionMain-tab-profile::after {
    position: absolute;
    content: " ";
    height: 32px;
    width: 25px;
    left: 20px;
    top: 20px;
    background: url("/public/assets/image/profile.png") no-repeat center;
    background-size: contain;
}

#SubscriptionMain-tab-profile.active::after {
    background: url("/public/assets/image/profile-active.png") no-repeat center;
    background-size: contain;
}

#SubscriptionMain-tab-profile:focus::after, 
#SubscriptionMain-tab-profile:hover::after {
    background: url("/public/assets/image/profile-active.png") no-repeat center;
    background-size: contain;
}

/*  */
#SubscriptionMain-tab-Payment::after {
    position: absolute;
    content: " ";
    height: 32px;
    width: 30.5px;
    left: 20px;
    top: 20px;
    background: url("/public/assets/image/payment.svg") no-repeat center;
    background-size: 100% 100%;
}

#SubscriptionMain-tab-Payment.active::after {
    background: url("/public/assets/image/payment-active.svg") no-repeat center;
    background-size: 100% 100%;
}

#SubscriptionMain-tab-Payment:focus::after, 
#SubscriptionMain-tab-Payment:hover::after {
    background: url("/public/assets/image/payment-active.svg") no-repeat center;
    background-size: 100% 100%;
}

/*  */
.subscription-main .cost-tab .nav-item:nth-child(4) .nav-link:after {
    position: absolute;
    content: " ";
    height: 32px;
    width: 32px;
    left: 20px;
    top: 20px;
    background: url("/public/assets/image/reading-list.svg") no-repeat center;
    background-size: contain;
}

.subscription-main .cost-tab .nav-item:nth-child(4) .nav-link.active::after {
    background: url("/public/assets/image/reading-list-active.svg") no-repeat center;
    background-size: contain;
}

.subscription-main .cost-tab .nav-item:nth-child(4) .nav-link:focus::after, 
.subscription-main .cost-tab .nav-item:nth-child(4) .nav-link:hover::after {
    background: url("/public/assets/image/reading-list-active.svg") no-repeat center;
    background-size: contain;
}

/*  */
.subscription-main .cost-tab .nav-item:nth-child(5) .nav-link:after {
    position: absolute;
    content: " ";
    height: 29.5px;
    width: 32px;
    left: 20px;
    top: 20px;
    background: url("/public/assets/image/current-stack.svg") no-repeat center;
    background-size: contain;
}

.subscription-main .cost-tab .nav-item:nth-child(5) .nav-link.active::after {
    background: url("/public/assets/image/current-stack-active.svg") no-repeat center;
    background-size: contain;
}

.subscription-main .cost-tab .nav-item:nth-child(5) .nav-link:focus::after, 
.subscription-main .cost-tab .nav-item:nth-child(5) .nav-link:hover::after {
    background: url("/public/assets/image/current-stack-active.svg") no-repeat center;
    background-size: contain;
}

/*  */
.subscription-main .cost-tab .nav-item:nth-child(6) .nav-link:after {
    position: absolute;
    content: " ";
    height: 29.5px;
    width: 32px;
    left: 20px;
    top: 20px;
    background: url("/public/assets/image/current-stack.svg") no-repeat center;
    background-size: contain;
}

.subscription-main .cost-tab .nav-item:nth-child(6) .nav-link.active::after {
    background: url("/public/assets/image/current-stack-active.svg") no-repeat center;
    background-size: contain;
}

.subscription-main .cost-tab .nav-item:nth-child(6) .nav-link:focus::after, 
.subscription-main .cost-tab .nav-item:nth-child(6) .nav-link:hover::after {
    background: url("/public/assets/image/current-stack-active.svg") no-repeat center;
    background-size: contain;
}

.profile1-main {
    position: relative;
    padding: 50px 0 130px;
}

.profile1-main .pro1-title {
    text-align: center;
    color: var(--yellow);
    margin-bottom: 23px;
    margin-top: 14px;
    text-transform: uppercase;
}

.profile1-main .s-plan {
    width: 42%;
    margin: 0 auto;
    position: relative;
}

.profile1-main .s-plan .plan-row {
    display: grid;
    border: 1px solid var(--green);
    grid-template-columns: 39% 61%;
    padding: 16px 30px;
    border-radius: 5px;
    margin-bottom: 10px;
}

.profile1-main .s-plan .plan-row p {
    position: relative;
    font-family: var(--indivisible);
}

/* profile tab */


.profile1-m1 {
    position: relative;
    padding: 50px 0 130px;
}

.profile1-m1 .pro1-title {
    text-align: center;
    color: var(--yellow);
    margin-bottom: 23px;
    margin-top: 14px;
    text-transform: uppercase;
}

.profile1-m1 .s-plan {
    width: 42%;
    margin: 0 auto;
    position: relative;
}

.profile1-m1 .p1-detail {
    width: 71%;
    margin: 0 auto;
    font-family: var(--indivisible);
    color: var(--green);
    padding-bottom: 20px;
}

.profile1-m1 .p1-detail a {
    text-decoration: underline !important;
}

.profile1-m1 .p1-detail a:hover {
    color: var(--yellow) !important;
}

.profile1-m1 .pro1-tab {
    width: 71%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
}

.profile1-m1 .form-controla {
    border: 1px solid var(--green) !important;
    border-radius: 5px !important;
    padding: 11px 12px;
    font-style: normal;
}

.profile1-m1 .cost-form-group label.form-label {
    font-size: var(--body-font-size) !important;
    line-height: var(--body-line-height) !important;
    font-family: var(--indivisible);
    margin-bottom: 0 !important;
}

.profile1-m1 .cost-form-group {
    grid-template-columns: 34% 66%;
    align-items: center;
}

.profile1-m1 .login-form-main .submit-cta button {
   margin-left: auto;
}

.profile1-m1 .w10-m .cost-form-group {
    grid-template-columns: 100%;
}

.profile1-m1 .payment-done80 {
    position: relative;
    width: 67%;
    margin: 0 auto;
}

.profile1-m1 .payment-done80 .p-head {
    display: grid;
    grid-template-columns: 9% 30% 15.5% 30% 15%;
    padding: 17px 40px;
}

.profile1-m1 .payment-done80 .p-head p {
    text-transform: uppercase;
    font-family: var(--indivisible);
    font-size: 14px;
    line-height: 26px;
    font-weight: 600;
}

.profile1-m1 .pay-history .p-row {
    border: 1px solid var(--green);
    display: grid;
    grid-template-columns: 9% 30% 15.5% 30% 15%;
    margin-bottom: 10px;
    padding: 17px 40px;
    border-radius: 5px;
}

.profile1-m1 .pay-history .p-row p {
    font-family: var(--indivisible);
    font-size: 14px;
    line-height: 26px;
    font-weight: 400;
}

.profile1-m1 .reading-list15 {
    position: relative;
    width: 67%;
    margin: 0 auto;
}

.profile1-m1 .current-stack15 {
    position: relative;
    width: 67%;
    margin: 0 auto;
}

.cost-order-tab {
    position: relative;
    width: 24%;
    margin: 0 auto;
    border-bottom: 2px solid var(--green) !important;
}

.cost-order-tab .nav-link {
    border: 0px solid transparent !important;
    font-family: var(--indivisible);
    font-size: var(--body-font-size);
    color: var(--green);
    border-radius: 0px !important;
    position: relative;
    height: max-content;
    text-align: left;
    font-size: 18px;
    padding: 10px 20px 15px;
}

.cost-order-tab .nav-link.active {
    background: transparent !important;
    color: var(--yellow) !important;
}

.cost-order-tab .nav-link:focus, .cost-order-tab .nav-link:hover {
    background: transparent !important;
    color: var(--yellow) !important;
}

.cost-order-tab .nav-link.active::after {
    position: absolute;
    content: " ";
    background: var(--yellow);
    height: 2px;
    width: 100%;
    left: 0;
    bottom: -1px;
}

.cost-order-tab .nav-link:focus::after, .cost-order-tab .nav-link:hover::after {
    position: absolute;
    content: " ";
    background: var(--yellow);
    height: 2px;
    width: 100%;
    left: 0;
    bottom: -1px;
}

.order-history .tab-content {
    width: 46%;
    margin: 0 auto;
    padding: 10px 0;
}

.order-book-card {
    position: relative;
    display: grid;
    grid-template-columns: 125px 1fr;
    gap: 30px;
    padding: 20px 0;
    justify-content: center;
    align-items: center;
}

.literary-cart .literary-row .empty-card p {
    position: relative;
    font-size: 20px;
    line-height: 1.3rem;
}

.order-book-card .book {
    position: relative;
    width: 121px;
    height: 180px;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
}

.order-book-card .book img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.order-book-card .top-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 15px;
}

.order-book-card .top-row.previous-v1 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.order-book-card .top-row .o-title {
    text-transform: uppercase;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
}

.order-book-card .top-row .o-sub-title {
    font-size: 16px;
    font-family: var(--indivisible);
    font-weight: 500;
    cursor: pointer;
}

.order-book-card .book-detail p {
    font-size: 16px;
    font-family: var(--indivisible);
    font-weight: 500;
}

.order-book-card .cta {
    position: relative;
    margin-top: 10px;
}

.order-book-card .book-name {
    position: relative;
    margin-top: 17px;
}

.order-book-card .book-name h3 {
    text-transform: uppercase;
    overflow: hidden;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    cursor: pointer;
}

.previous-orders {
    position: relative;
    height: 800px;
    overflow: auto;
}

.previous-orders::-webkit-scrollbar {
    width: 0px;
  }
  
.current-orders {
    position: relative;
    height: 800px;
    overflow: auto;
}

.current-orders::-webkit-scrollbar {
    width: 0px;
  }

/*  */
#OrderNowMain-tab-OrderNow::after {
    position: absolute;
    content: " ";
    height: 28px;
    width: 32px;
    left: 20px;
    top: 20px;
    background: url("/public/assets/image/shopping-cart.svg") no-repeat center;
    background-size: contain;
}

#OrderNowMain-tab-OrderNow.active::after {
    background: url("/public/assets/image/shopping-cart-active.svg") no-repeat center;
    background-size: contain;
}

#OrderNowMain-tab-OrderNow:focus::after, 
#OrderNowMain-tab-OrderNow:hover::after {
    background: url("/public/assets/image/shopping-cart-active.svg") no-repeat center;
    background-size: contain;
}

/*  */
#OrderNowMain-tab-ReturnNow::after {
    position: absolute;
    content: " ";
    height: 30px;
    width: 32px;
    left: 20px;
    top: 20px;
    background: url("/public/assets/image/return-now-active.svg") no-repeat center;
    background-size: contain;
}

#OrderNowMain-tab-ReturnNow.active::after {
    background: url("/public/assets/image/return-now.svg") no-repeat center;
    background-size: contain;
}

#OrderNowMain-tab-ReturnNow:focus::after, 
#OrderNowMain-tab-ReturnNow:hover::after {
    background: url("/public/assets/image/return-now.svg") no-repeat center;
    background-size: contain;
}

.order-page-hero {
    position: relative;
    width: 84.5%;
    padding-left: 90px;
    background: var(--green);
}

.order-page-hero::after {
    position: absolute;
    content: "";
    width: 94.5%;
    height: 364px;
    left: 0;
    bottom: 0;
    background: url(/public/assets/image/bg.png), var(--bg-color);
    border-radius: 0 10px 0 0;
}

.order-page-hero .hero-s-title {
    padding-top: 299px;
    color: var(--white);
    text-transform: uppercase;
    text-align: center;
}

.order-page-hero .hero-title {
    color: var(--white);
    width: 63%;
    text-align: center;
    margin: 0 auto;
    text-transform: uppercase;
    margin-top: 29px;
    padding-bottom: 439px;
}

.subscription-main.order-now-main {
    margin-top: -304px;
    position: relative;
}

.subscription-main.order-now-main .cost-tab {
    width: 100%;
    justify-content: flex-start;
}

.subscription-main.order-now-main .tab-content {
    position: relative;
    margin-bottom: 90px;
}

.literary-cart {
    display: grid;
    grid-template-columns: 57% 39%;
    gap: 4%;
    margin-top: 30px;
}

.place-order-card {
    background: var(--dark-green);
    border-radius: 10px;
    padding: 40px;
    position: relative;
}

.login-button {
    padding: 10px 60px;
}

.place-order-card .place-order {
    position: absolute;
    bottom: -20px;
    z-index: 999;
    left: 22%;
}

.place-order-card .card-heading {
    color: var(--yellow);
    text-transform: uppercase;
}

.literary-cart .top {
    position: relative;
}

.literary-cart .top .top-heading {
    font-size: 38px;
    position: relative;
    line-height: 44px;
    margin-top: 33px;
}

.literary-cart .book-return {
    position: relative;
    color: var(--white);
    padding: 18px 0;
}

.literary-cart .book-return .heading-h5 {
    color: var(--yellow);
}

.literary-cart .top .top-heading::after {
    position: absolute;
    content: " ";
    right: 0px;
    top: 25px;
    width: 66%;
    height: 2px;
    background: var(--yellow);
}

.literary-cart .delivery {
    padding-right: 90px;
}

.literary-cart .literary-row {
    max-height: 700px;
    min-height: 50px;
    overflow: auto;
    margin-bottom: 50px;
    padding-right: 15px;
}

.literary-cart .literary-row .empty-card {
    margin-top: 24px;
}

.literary-cart .literary-row .l-row {
    display: grid;
    grid-template-columns: 51px 1fr;
    border-bottom: 1px dotted var(--yellow);
    justify-content: center;
    align-items: center;
    max-height: 700px;
    overflow: auto;
    padding-right: 15px;
}

.literary-cart .literary-row::-webkit-scrollbar {
   width: 3px;
}
  
.literary-cart .literary-row .c-icon {
    width: 20px;
    height: 25px;
    position: relative;
    cursor: pointer;
}

.literary-cart .literary-row .c-icon img {
    height: 100%;
    width: 100%;
    object-fit: fill;
    margin-bottom: 20px;
}

.cost-form .form-check-input[type=checkbox] {
    border-radius: 3px;
    border: 1px solid var(--dark-green);
    background: transparent;
    width: 18px;
    height: 18px;
}

.cost-form .form-check-input:checked[type=checkbox] {
    background: url(/public/assets/image/Icon-feather-check.svg), var(--dark-green);
    background-repeat: no-repeat;
    background-size: inherit;
    background-position: center center;
}

.cost-form .form-check-input:focus {
    border-color: var(--dark-green);
    outline: 0;
    box-shadow: none;
}

.order-book-card .in-stock p {
    font-size: 16px;
    position: relative;
    padding-left: 25px;
}

.order-book-card .in-stock p::after {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    background-image: url('/public/assets/image/instock-icon.png');
    background-size: cover;
    left: 0;
    top: 6px;
}

.order-book-card .out-stock p {
    font-size: 16px;
    color: var(--notify);
    position: relative;
    padding-left: 25px;
}

.order-book-card .out-stock p::after {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    background-image: url('/public/assets/image/notify-icon.png');
    background-size: cover;
    left: 0;
    top: 6px;
}

.order-book-card .out-stock {
    display: grid;
    grid-template-columns: 110px 1fr;
    gap: 45px;
    align-items: baseline;
}

.order-book-card .notify-btn {
    border: 0 !important;
}

.order-book-card .notify-btn {
    padding: 0;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.order-book-card .reading-list {
    margin-bottom: 10px;
    margin-top: 20px;
}

.order-book-card .in-stock {
    margin-top: 20px;
}

.order-book-card .reading-list p {
    position: relative;
    width: max-content;
    margin-bottom: 10px;
    cursor: pointer;
}

.order-book-card .reading-list p::after {
    position: absolute;
    content: "";
    bottom: -3px;
    left: 0;
    width: 100%;
    height: 2px;
    background: var(--green);
}

.literary-cart .delivery .l-side {
    color: var(--yellow);
    font-family: var(--indivisible);
}

.literary-cart .delivery .r-side {
    color: var(--white);
    font-family: var(--indivisible);
}

.literary-cart .delivery .address {
    display: grid;
    grid-template-columns: 37% 59%;
    gap: 18px;
    border-bottom: 1px dotted var(--yellow);
    padding-bottom: 30px;
    padding-top: 16px;
}

.place-order-card .book-ordered .ordered {
    color: var(--white);
    margin: 0 !important;
    padding-left: 0px;
    border-bottom: 1px dotted var(--yellow);
    padding-bottom: 22px;
}

.place-order-card .book-ordered .book-ordered {
    position: relative;
    color: var(--white);
    padding-top: 23px;
}

.place-order-card .book-ordered .ordered li {
    position: relative;
    padding-left: 18px;
    font-family: var(--indivisible);
}

.place-order-card .book-ordered .ordered li::before {
    position: absolute;
    content: " ";
    top: 10px;
    left: 0;
    right: 0;
    height: 5px;
    width: 5px;
    background-image: url('/public/assets/image/detail-list-icon.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.literary-cart .book-return .return-detail {
    font-family: var(--indivisible);
}


.place-order-card .book-ordered {
    color: var(--yellow);
    padding: 12px 0 20px;
}

.literary-cart .order-book-card .book {
    width: 146px;
    height: 220px;
}

.literary-cart .order-book-card {
    position: relative;
    display: grid;
    grid-template-columns: 155px 1fr;
    gap: 54px;
    padding: 30px 0;
}

/* demand-card hover */

.demand-card .hero-hover {
    position: absolute;
    top: 0;
    z-index: 2;
    height: 600px;
    width: 100%;
    overflow: hidden;
    border-radius: 15px;
    /* -webkit-backdrop-filter: url(/public/assets/image/background-hover.png);
    backdrop-filter: url(/public/assets/image/background-hover.png); */
    background: rgba(255, 255, 255, 0.5);
    -webkit-backdrop-filter: blur(10px) saturate(150%);
    backdrop-filter: blur(10px) saturate(150%);
    display: none;
    transition: all 200ms ease-in;
}

.demand-card:hover .hero-hover {
    display: block;
}

.demand-card .hero-hover .hover-content {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: max-content;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    width: 100%;
    gap: 10px;
}

.demand-card .hero-hover button {
    font-family: var(--indivisible);
    font-weight: 600;
}

.demand-card .info.more-info .info-icon {
    height: 24px;
    width: 24px;
    background: transparent;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 13px;
    background-image: url(/public/assets/image/more-info-v1.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.demand-card .info.cart .info-icon {
    height: 21px;
    width: 24px;
    background: transparent;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 18px;
    background-image: url(/public/assets/image/shopping-cart.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.demand-card .info.reading .info-icon {
    height: 22px;
    width: 22px;
    background: transparent;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 10px;
    background-image: url(/public/assets/image/book-group.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.demand-card .hero-hover .hover-content .info {
    background: var(--white);
    color: var(--dark-green);
    padding: 10px 17px;
    width: calc(100% - 100px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
}

.demand-card .hero-hover .hover-content .info.reading:hover {
    background: var(--dark-green);
    color: var(--white);
}

.demand-card .hero-hover .hover-content .info.reading:hover .info-icon{
    background-image: url(/public/assets/image/book-group-yellow.png);
}

.demand-card .hero-hover .hover-content .info.more-info:hover {
    background: var(--dark-green);
    color: var(--white);
}

.demand-card .hero-hover .hover-content .info.more-info:hover .info-icon{
    background-image: url(/public/assets/image/more-hover-v1.png);
}

.demand-card .hero-hover .hover-content .info.cart {
    background: var(--yellow);
}

.demand-card .hero-hover .hover-content .info.cart:hover {
    background: var(--dark-green);
    color: var(--white);
}

.demand-card .hero-hover .hover-content .info.cart:hover .info-icon{
    background-image: url(/public/assets/image/shopping-cart-yellow.png);
}

#splide02-list #splide02-slide01 .demand-card .number {
    left: 98px !important;
    position: absolute;
}

.filter-mobile #divMsg {
    display: none;
    position: absolute;
    top: 17px;
    width: 91%;
    left: 20px;
    background: var(--bg-color);
    border: 1px solid var(--green);
    border-radius: 10px;
    z-index: 2;
    padding: 5px 20px 20px;
}

.overview-filter-main .filter-mobile input[type="button"] {
    border: 1px solid var(--green);
    border-radius: 10px;
    background: var(--bg-color);
    font-size: 15px;
    color: var(--dark-green);
    text-transform: uppercase;
    font-weight: 500;
    padding: 18px 20px;
    width: 177px;
    text-align: left;
    position: relative;
    background-image: url(/public/assets/image/select-dropdown.svg);
    background-repeat: no-repeat;
    background-position: 94%;
    background-size: 10px;
    font-family: var(--indivisible);
}

.overview-filter-main .filter-mobile input[type="button"]::after {
    position: absolute;
    content: "";
    right: 15px;
    top: 74px;
    width: 11px;
    height: 8px;
    background-image: url(/public/assets/image/arrow-down-dropdown.svg);
    background-repeat: no-repeat;
    background-position: top left;
}

.filter-mobile #divMsg {
    display: none;
}

.overview-filter-main .filter-mobile {
    display: none;
}

.filter-mobile #divMsg .custom-dropdown {
    width: 100%;
    border: 0px;
}

.filter-mobile #divMsg  .custom-dropdown::after {
    position: absolute;
    content: "";
    right: 15px;
    top: 36px;
    width: 11px;
    height: 8px;
}

.filter-mobile #divMsg .custom-dropdown .selected-option {
    padding: 25px 20px 23px 85px;
    font-size: 18px;
    line-height: 26px;
    font-family: var(--indivisible);
    position: relative;
}

.filter-mobile #divMsg input[type="button"] {
    border-bottom: 1px solid var(--green) !important;
    border: 0;
    border-radius: 0;
    background: var(--bg-color);
    font-size: 15px;
    color: var(--dark-green);
    text-transform: uppercase;
    font-weight: 500;
    padding: 17px 10px;
    width: 100%;
    text-align: left;
    position: relative;
    background-image: url(/public/assets/image/select-dropdown.svg);
    background-repeat: no-repeat;
    background-position: 44%;
}

.overview-filter-main .filter-mobile .right-mobile .selected-option.popular::after {
    display: none;
}

.overview-filter-main .filter-mobile .right-mobile  .custom-dropdown::after {
    right: 15px;
    top: 30px;
    width: 11px;
    height: 8px;
}

.overview-filter-main .filter-mobile .right-mobile .custom-dropdown .selected-option {
    padding: 17px 20px 17px;
    font-size: 18px;
    line-height: 26px;
}

.filter-mobile #divMsg input .overview-filter-main {
    width: 100%;
    padding: 20px 20px 15px;
    margin-top: -50px;
}

.admin-panel-main .panel-row .left {
    height: 100vh;
    width: 15.75%;
    background: var(--dark-green);
    position: fixed;
    padding: 30px;
    top: 0;
    left: 0;
    bottom: 0;
    right: auto;
    z-index: 999;
}

.admin-panel-main .panel-row .menu-list {
    position: relative;
    background: var(--green);
    border-radius: 5px;
}

.admin-panel-main .panel-row .bottom {
    position: absolute;
    width: 239px;
    bottom: 40px;
    left: 29px;
    margin-top: 0px;
}

.admin-panel-main .panel-row .bottom .log-out {
    border-top: 1px solid #F7F5E9;
    border-bottom: 1px solid #F7F5E9;
    padding: 12px 0;
}

.admin-panel-main .panel-row .bottom .log-out a {
    color: var(--white) !important;
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    font-family: var(--indivisible);
}

.admin-panel-main .panel-row .bottom .log-out a span {
    margin-right: 12px;
}

.admin-panel-main .panel-row .left .panel-name {
    display: grid;
    grid-template-columns: 52px 1fr;
    gap: 10px;
    border-top: 1px solid #F7F5E9;
    border-bottom: 1px solid #F7F5E9;
    padding: 25px 0px 24px;
}

.admin-panel-main .logo {
    width: 150px;
    position: relative;
    height: 47.1px;
    margin: 0 auto;
    overflow: hidden;
    cursor: pointer;
}

.admin-panel-main .logo img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.admin-panel-main .panel-row .left .panel-name .image {
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 100%;
}

.admin-panel-main .panel-row .left .panel-name .image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.admin-panel-main .left .panel-name .name {
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    color: var(--white);
    font-family: var(--indivisible);
}

.admin-panel-main .left .panel-name .member {
    font-size: 14px;
    line-height: 26px;
    color: #f8f5e987;
    font-family: var(--indivisible);
}

.admin-panel-main .left .panel-title p {
    font-size: 20px;
    line-height: 34px;
    color: var(--yellow);
    font-weight: 500;
    padding: 33px 0 8px;
}

.admin-panel-main .left .admin-menu-main {
    padding: 20px 10px !important;
    margin-top: 20px;
    max-height: 55vh;
    overflow: auto;
}

.admin-panel-main .left .admin-menu-main::-webkit-scrollbar {
    width: 5px;
    background: var(--white);
    border-radius: 5px;
  }
  
.admin-panel-main .left .admin-menu-main::-webkit-scrollbar-thumb {
    background: var(--yellow); 
    border-radius: 10px;
  }
  

.admin-panel-main .left .admin-menu-main .admin-menu a img {
    width: 19px;
}

.admin-panel-main .left .admin-menu-main .admin-menu a {
    font-size: 17px;
    color: var(--white) !important;
    font-family: var(--indivisible);
    padding: 8px 8px;
    display: grid;
    grid-template-columns: 31px 1fr;
    justify-content: center;
    align-items: center;
}

.admin-panel-main .left .admin-menu-main .admin-menu.active a {
    background: var(--dark-green);
    border-radius: 3px;
}

.admin-panel-main .left .admin-menu-main .admin-menu:hover a {
    background: var(--dark-green);
    border-radius: 3px;
}

.admin-panel-main .right .panel-title-top.all-book {
    display: grid;
    grid-template-columns: 29% 70%;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;
    position: relative;
    width: 100%;
}

.admin-panel-main .right .panel-title-top .top-left .top-back-12 {
    margin-bottom: 20px;
}

.admin-panel-main .dash-filter.filter {
    position: absolute;
    top: 55px;
    width: 100%;
    display: grid;
    grid-template-columns: 60% 40%;
    left: 0;
    padding: 0px 90px 0 0;
    justify-content: flex-start;
    align-items: start;
}

.admin-panel-main .panel-row .right {
    position: relative;
    width: 82%;
    margin-left: auto;
    padding-right: 90px;
}

.admin-panel-main .right .panel-title-top {
    display: grid;
    grid-template-columns: 30% 70%;
    justify-content: space-between;
    align-items: start;
    margin-top: 40px;
    position: relative;
}

.admin-panel-main .dash-filter.filter .left-fil {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
}

/* filter */
.dash-filter.filter .selected-option.category::after {
    display: none;
}

.dash-filter.filter .selected-option.author::after {
    display: none;
}

.dash-filter.filter .selected-option.publisher::after {
    display: none;
}

.dash-filter.filter .custom-dropdown .selected-option {
    padding: 11px 15px 11px;
    font-size: 15px;
    line-height: 19px;
    font-weight: 600;
}

.dash-filter.filter .custom-dropdown::after {
    right: 15px;
    top: 15px;
    width: 11px;
    height: 8px;
}

.dash-filter.filter .custom-dropdown .dropdown-options .option {
    font-size: 16px;
    line-height: 19px;
    padding: 10px 15px;
    font-weight: 400;
}

.dash-filter.filter .custom-dropdown {
    border: 1px solid var(--green);
    border-radius: 5px;
    width: 100%;
    z-index: 9999;
}

.admin-panel-main .admin-panel-data {
    margin-top: 80px;
    width: 100%;
}

.admin-panel-data.order-list85 {
    width: 100%;
    overflow: auto;
}

.admin-panel-data.order-list85 .tb-res-v1 {
    width: 130%;
}

.admin-panel-data.order-list85::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
  }
  
.admin-panel-data.order-list85::-webkit-scrollbar-thumb {
    background: var(--green); 
    border-radius: 5px;
}

.admin-panel-main .right .panel-title-top .top-right {
    display: grid;
    grid-template-columns: 100%;
    gap: 0%;
}

.admin-panel-main .right .panel-title-top .right-side {
    margin-left: auto;
    display: flex;
    gap: 20px;
}

.admin-search-input button {
    background: var(--green);
    border-color: var(--green);
}

.admin-search-input button:hover, .admin-search-input button:focus {
    background: var(--dark-green);
    border-color: var(--dark-green);
}

.admin-search-input input.form-control {
    border-color: var(--green);
    background: transparent;
    font-family: var(--indivisible);
    padding: 9px .75rem !important;
    color: var(--green) !important;
}

.admin-search-input input.form-control:focus {
    color: var(--green);
    border-color: var(--dark-green);
    background-color: transparent;
    outline: 0;
    box-shadow:none;
}

/* filter end */

.admin-panel-main .dash-filter.filter .search {
    padding-left: 200px;
}

.admin-panel-main .right .panel-title-top .heading-h3 {
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0;
}

.e-edit-delete-value .edit-action img {
    width: 22px;
}

.e-edit-delete-value .delete-action img {
    width: 22px;
}

.admin-panel-data .top-title {
    display: grid;
    grid-template-columns: 4.5% 8.5% 31% 11% 10% 6% 7% 7% 7%;
    gap: 1%;
    padding: 55px 16px 10px;
    justify-content: start;
    align-items: center;
    margin-top: 0;
    border-top: 1px solid var(--dark-green);
}

.admin-panel-data .top-title .e-status {
    text-align: center;
}

.admin-panel-data .member-plans {
    display: grid;
    grid-template-columns: 15% 13% 24% 20% 13% 10%;
    gap: 1%;
    padding: 55px 16px 10px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    border-top: 1px solid var(--dark-green);
}

.admin-panel-data .book-list .book-row.member-plans {
    display: grid;
    grid-template-columns: 15% 13% 24% 20% 13% 10%;
    gap: 1%;
    justify-content: start;
    align-items: center;
    border: 1px solid var(--yellow);
    padding: 10px 16px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.admin-panel-data .top-title.all-plans {
    display: grid;
    grid-template-columns: 23% 43.5% 12% 17%;
    gap: 1.5%;
    padding: 40px 16px 10px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0;
    border-top: 1px solid var(--dark-green);
}

.admin-panel-data .top-title.all-plans .e-status {
    text-align: center;
}

.admin-panel-data .book-row.all-plans {
    display: grid;
    grid-template-columns: 23% 43.5% 12% 17% !important;
    gap: 1.5%;
}

.admin-panel-data .top-title .top-t {
    font-size: 16px;
    line-height: 26px;
    font-family: var(--indivisible);
    font-weight: 600;
    text-transform: capitalize;
}

.add-remove-input {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.add-remove-input .make {
    background: var(--dark-green);
    color: var(--white);
    font-family: var(--indivisible);
    width: max-content;
    cursor: pointer;
    padding: 3px 38px 3px 13px;
    border-radius: 3px;
    font-size: 15px;
    display: block;
    font-weight: bold;
    transition: all 0.5s;
    background-image: url(/public/assets/image/add-plan-white.png);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 89% 9px;
    margin-left: auto;
}

.add-remove-input .make:hover {
    background: var(--yellow);
    color: var(--green);
    background-image: url(/public/assets/image/add-plan.png);
    background-size: 14px;
    background-repeat: no-repeat;
    background-position: 89% 9px;
}

.add-remove-input .remove {
    background: var(--yellow);
    color: var(--green);
    font-family: var(--indivisible);
    width: max-content;
    cursor: pointer;
    padding: 3px 38px 3px 13px;
    border-radius: 3px;
    font-size: 15px;
    display: block;
    font-weight: bold;
    transition: all 0.5s;
    background-image: url(/public/assets/image/close-mocal.png);
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: 87% 12px;
}

.add-remove-input .remove:hover {
    background: var(--dark-green);
    color: var(--white);
    background-image: url(/public/assets/image/close-mocal-white.png);
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: 87% 12px
}

.admin-panel-data .book-list .top-value {
    font-size: 16px;
    line-height: 1.4em;
    font-family: var(--indivisible);
}

/* .admin-panel-data .book-list .title-value .top-value {
    font-family: var(--lora);
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 20px;
} */

.admin-panel-data .book-list .title-value .top-value {
    font-family: var(--lora);
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    display: -webkit-box;
    overflow: auto;
    text-overflow: ellipsis;
    padding-right: 5px;
}

.admin-panel-data .book-list .title-value .top-value::-webkit-scrollbar {
    width: 3px;
    background: var(--yellow);
    border-radius: 3px;
  }
  
  .admin-panel-data .book-list .title-value .top-value::-webkit-scrollbar-thumb {
    background: var(--green); 
    border-radius: 5px;
  }

.admin-panel-data .book-list .title-value .top-value.email {
    text-transform: lowercase !important;
}

.admin-panel-main .e-edit-delete-value {
    justify-content: space-around;
    align-items: center;
    display: flex;
}

.admin-panel-main .admin-panel-data .e-status-value {
    text-align: center;
    padding-top: 8px;
}

.admin-panel-main .e-edit-delete {
    text-align: center;
}

.e-status-value .toggle-off {
    position: relative;
    height: 30px;
    width: 50px;
    cursor: pointer;
    background-image: url(/public/assets/image/toggle-icon-off.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.e-status-value .toggle-on {
    position: relative;
    height: 30px;
    width: 50px;
    cursor: pointer;
    background-image: url(/public/assets/image/toggle-icon-on.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
}

.e-date-value .top-value {
    text-align: center;
}

.admin-panel-data .p-img-value .book-image {
    width: 46.67px;
    height: 70px;
    border-radius: 3px;
    overflow: hidden;
}

.admin-panel-data .p-img-value .book-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.admin-panel-data .book-list .book-row {
    display: grid;
    grid-template-columns: 3.5% 9.5% 31% 11% 10% 6% 7% 7% 7%;
    gap: 1%;
    justify-content: start;
    align-items: center;
    border: 1px solid var(--yellow);
    padding: 10px 16px;
    border-radius: 10px;
    margin-bottom: 10px;
}

.book-panel-form .pan-form-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 70px;
}

.book-panel-form .pan-form-row .ck-editor-v1 {
    grid-column: 1 / 4;
}

.ck-editor-v1 .ck.ck-editor__main .ck-content {
    padding-bottom: 15px;
    height: 200px;
    overflow: auto;
}

.book-panel-form .pan-form-row .cost-form-group {
    grid-template-columns: 1fr;
}

.book-panel-form .submit-cta {
    position: relative;
    width: 100%;
    margin-top: 23px;
    margin-bottom: 100px;
}

.book-panel-form .submit-cta button {
    margin-left: auto;
}

.book-panel-form  .cost-form-group label.form-label {
    font-size: var(--body-font-size);
    line-height: var(--body-line-height);
    font-family: var(--indivisible);
    color: var(--green);
    margin-bottom: 10px;
    font-weight: 500;
}

.book-panel-form  .cost-form-group {
    margin-bottom: 30px;
}

.add-book-form {
    position: relative;
    margin-top: 26px;
}

.add-book-form .form-control {
    border: 1px solid var(--dark-green);
    font: var(--body-font-size);
    line-height: var(--body-line-height);
    color: var(--dark-green) !important;
    background: transparent;
    border-radius: 5px !important;
    padding: .7rem 0.75rem;
}

.add-book-form .form-control:focus {
    color: var(--dark-green);
    background-color: transparent;
    border-color: var(--dark-green);
    outline: 0;
    box-shadow: none;
}

.add-book-form .form-select {
    border: 1px solid var(--dark-green);
    font: var(--body-font-size);
    line-height: var(--body-line-height);
    color: var(--dark-green) !important;
    border-radius: 5px !important;
    padding: .7rem 31px .7rem 0.75rem;
    position: relative;
    background: transparent;
    background-image: url(/public/assets/image/select-dropdown.svg);
    background-repeat: no-repeat;
    background-position: 96%;
}

.add-book-form .form-select:focus {
    border-color: var(--dark-green);
    outline: 0;
    box-shadow: none;
}

.add-book-form .form-control::placeholder {
    color: var(--dark-green);
    opacity: 0.5;
  }
  
.add-book-form .form-control::-ms-input-placeholder {
    color: var(--dark-green);
    opacity: 0.5;
}

.detail.demand-slider {
    width: 84.5%;
    position: relative;
    padding: 0 90px;
}

.detail.demand-slider .fiction-slide-card .card-image {
    width: 100%;
    height: 493.13px;
}

.detail.demand-slider .fiction-slide-card .hero-hover {
    height: 500px;
}

.detail.demand-slider .splide__arrows.splide__arrows--ltr {
    display: none;
}

.books-pagination {
    position: relative;
    width: 100%;
    display: grid;
    justify-content: center;
    align-items: center;
    margin: 40px 0 10px;
}

.books-pagination .page-link:hover {
    z-index: 3;
    color: var(--white) !important;
    background-color: var(--green) !important;
    outline: 0;
    box-shadow: none;
}

.books-pagination .page-link:focus {
    z-index: 3;
    color: var(--dark-green) !important;
    background-color: var(--yellow) !important;
    outline: 0;
    box-shadow: none;
}

.books-pagination .pagination .page-link {
    position: relative;
    display: block;
    padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
    font-size: 18px;
    color: var(--dark-green);
    text-decoration: none;
    background-color: var(--yellow);
    border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
}

.books-pagination .page-item.active .page-link {
    background: var(--green);
    color: var(--white);
}

.admin-panel-main .right .panel-title-top .login-button {
    font-family: var(--indivisible);
    color: var(--dark-green) !important;
    background: var(--yellow) !important;
    padding: 8px 20px;
    border-radius: 0px;
    font-size: 14px;
}

.admin-panel-main .right .panel-title-top .login-button:hover {
    color: var(--white) !important;
    background: var(--dark-green) !important;
}

.admin-panel-main .right .panel-title-top .login-button.upload-b span {
    background: url(/public/assets/image/uplod-books.png) no-repeat center;
    background-size: 20px;
}

.admin-panel-main .right .panel-title-top .login-button.upload-b:hover span {
    background: url(/public/assets/image/uplod-books-white.png) no-repeat center;
    background-size: 20px;
}

.admin-panel-main .right .panel-title-top .login-button.add-b span {
    background: url(/public/assets/image/add-book.png) no-repeat center;
    background-size: 20px;
}

.admin-panel-main .right .panel-title-top .login-button.add-b:hover span {
    background: url(/public/assets/image/add-book-white.png) no-repeat center;
    background-size: 20px;
}

/* custom upload file  */

.custom-upload-file {
    width: 100%;
    display: grid;
    grid-template-columns: 80% 20%;
    position: relative;
}

.custom-upload-file .f-input {
    height: 52px;
    background-color: transparent;
    border: 1px solid var(--green);
    width: 100%;
    max-width: 100%;
    float: left;
    padding: 0 14px;
    font-family: var(--indivisible);
    border-radius: 5px 0 0 5px;
}

.custom-upload-file .fileUpload {
    position: relative;
    overflow: hidden;
}

.custom-upload-file .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;
    filter: alpha(opacity=0);
}

.custom-upload-file .fileUpload.btn.btn--browse {
    border: 1px solid var(--green);
    border-left: 0;
    border-radius: 0 5px 5px 0;
    background-color: var(--green);
    color: var(--white);
    padding: 8px 16px;
}

.custom-upload-file .fileUpload.btn.btn--browse .f-input{
	height: 42px;
	background-color: white;
	border: 1px solid gray;
	width: 100%;
	max-width: 400px;
	float: left;
	padding: 0 14px;
}

.cost-book-modal .btn-close {
    color: var(--green) !important;
    background-image: url("/public/assets/image/close-mocal.png") !important;
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 1;
    --bs-btn-close-focus-shadow: none;
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.50;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: var(--green) !important;
    background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    opacity: var(--bs-btn-close-opacity);
}

.add-file-modal .cta-book-mocal button {
    margin-top: 20px;
    margin-left: auto;
}

.add-file-modal  .login-button {
    padding: 8px 24px;
}

.join-us-modal .add-new .new-title {
    font-size: 18px;
    line-height: 1.2em;
    font-family: var(--indivisible);
    font-weight: 600;
}

.join-us-modal .add-new .top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--green);
}

.join-us-modal .add-new .top img {
    width: 150px;
    margin-left: auto;
}

.join-us-modal .btn-close {
    color: var(--green) !important;
    background-image: url("/public/assets/image/close-mocal.png") !important;
    --bs-btn-close-opacity: 0.5;
    --bs-btn-close-hover-opacity: 1;
    --bs-btn-close-focus-shadow: none;
    --bs-btn-close-focus-opacity: 1;
    --bs-btn-close-disabled-opacity: 0.50;
    --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: var(--green) !important;
    background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    opacity: 1;
}

.join-us-modal .btn-close:hover {
    opacity: 0.7;
}

.join-us-modal .modal-header {
    background: var(--yellow);
}

.join-us-modal .login-form-main.chose-pln {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}

.join-us-modal .cost-form-group {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr !important;
}

.join-us-modal .cost-form-group .form-label {
    margin-bottom: 10px !important;
    margin-right: 0 !important;
    font-size: 18px;
    line-height: 1.2em;
    padding-left: 0px;
    font-family: var(--indivisible);
    font-weight: 700;
}

.join-us-modal .cost-form-group .form-controla {
    font-size: 16px !important;
    line-height: 1.2em !important;
    border-bottom: 1.5px solid var(--yellow) !important;
    font-family: var(--indivisible);
    font-style: normal;
}

.join-us-modal .choose-cards .top1 p {
    font-size: 18px;
    font-weight: 600;
    font-family: var(--indivisible);
    border-bottom: 1px solid var(--yellow);
    padding-bottom: 7px;
    margin-top: 25px;
}

.join-us-modal .choose-cards {
    position: relative;
    height: max-content;
    padding-right: 0;
}

.join-us-modal .choose-cards .plan-detail-group {
    position: relative;
    height: 250px;
    overflow: auto;
    padding-right: 15px;
}

.join-us-modal .choose-cards .plan-detail-group::-webkit-scrollbar {
    width: 5px;
  }

.join-us-modal .cost-form-group.choose-cd {
    display: grid;
    grid-template-columns: 18% 57% 17% 1fr !important;
    justify-content: center;
    align-items: center;
}

.join-us-modal .cost-form-group.choose-cd .c-image img {
    width: 90px;
}

.join-us-modal .plan-detail {
    position: relative;
    padding: 10px 20px;
    border: 1px solid var(--green);
    margin-top: 20px;
    border-radius: 4px;
}

.join-us-modal .cost-form-group.choose-cd .detail label {
    display: block;
    cursor: pointer;
}

.join-us-modal .cost-form-group.choose-cd .pay-now {
    text-align: center;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
}

.join-us-modal .cost-form-group.choose-cd .pay-now input {
    height: 22px;
    width: 22px;
    border: 1.5px solid var(--dark-green);
}

.join-us-modal .cost-form-group.choose-cd .pay-now .login-button {
    font-size: 15px;
    line-height: 1.2em;
    font-family: var(--indivisible);
    color: var(--dark-green) !important;
    background: var(--yellow) !important;
    padding: 8px 19px;
    border-radius: 4px;
}

.join-us-modal .cost-form-group.choose-cd .pay-now .login-button:hover {
    color: var(--white) !important;
    background: var(--green) !important;
}

.join-us-modal .cost-form-group.choose-cd .pay-now .login-button span {
    display: none;
}

.join-us-modal .cost-form-group.choose-cd .detail label {
    font-size: 16px;
    font-family: var(--indivisible);
}

.join-us-modal .modal-body {
    position: relative;
    padding: 0px 30px 40px;
}

.join-us-modal .plan-detail .delete-icon {
    text-align: center;
}

.join-us-modal .plan-detail .delete-icon img {
    cursor: pointer;
}

.picked-titles .border-right .mobile {
    display: none;
}

.hero-left .show-mobile {
    display: none;
}

.t-and-c-main .left .term-mobile {
    display: none;
}

.literary-cart.mobile {
    width: 100%;
    height: fit-content;
    display: none;
}

.proceed-details {
    position: relative;
    width: 100%;
    margin-top: 20px;
}
.proceed-details button {
    width: 100%;
}

.our-story-s2 .right-s2 .image2.for-mobile {
    width: 100%;
    height: 374px;
    position: relative;
    overflow: hidden;
    border-radius: 15px;
    margin-bottom: 20px;
    display: none;
}

.sign-form-main.sign-modal {
    max-width: 90%;
    padding: 30px 0 50px;
}

.sign-form-main.sign-modal .have-account p {
    display: flex;
    gap: 10px;
}

.common-card.active .plan-card {
    border: 2px solid var(--green);
}

.cost-book-modal.con-check .accept-main {
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
}

.cost-book-modal.con-check .modal-title.h4 {
    font-size: 17px;
}

.accept-reject {
    background: var(--green);
    color: var(--white);
    padding: 5px 20px;
    border-radius: 20px;
    font-size: var(--body-font-size);
    font-family: var(--indivisible);
}

.accept-reject:hover {
    background: var(--yellow);
    color: var(--green);
}

.admin-panel-data.retrieval-order .top-title {
    grid-template-columns: 13% 19% 25% 26% 14%;
    gap: 10px;
    padding: 55px 16px 10px;
    justify-content: left;
}

.admin-panel-data.retrieval-order .top-title .e-edit-delete {
    text-align: left;
    padding-left: 10px;
}

.admin-panel-data.retrieval-order .book-list .book-row {
    grid-template-columns: 13% 19% 25% 26% 14%;
    gap: 10px;
}

.admin-panel-data.retrieval-order .book-list .book-row div:last-child {
    text-align: center;
}

.modal-dialog-centered .book-image {
    width: 71px;
    height: 100px;
    overflow: hidden;
    position: relative;
    border-radius: 10px;
}

.modal-dialog-centered .book-image img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.retrieval-modal .retrieval-m {
    display: grid;
    grid-template-columns: 10% 18% 28% 19% 21%;
    gap: 1%;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
    border: 1px solid var(--green);
    border-radius: 10px;
    padding: 10px;
}

.retrieval-modal .r-date {
    display: flex;
    gap: 50px;
    border-bottom: 1px solid var(--green);
    margin-bottom: 22px;
    padding-bottom: 20px;
    align-items: center;
    justify-content: space-between;
}

.retrieval-modal .r-date input[type="date"] {
    border: 1px solid var(--green);
    border-radius: 5px;
    padding: 5px;
}

.retrieval-modal .modal-body {
    max-height: 450px;
    overflow-x: auto;
}

.retrieval-modal .modal-content {
    padding-right: 15px;
}

.retrieval-modal .modal-content .modal-title button {
    margin-left: 50px !important;
}

.admin-panel-data.order-list85 .top-title {
    padding: 36px 16px 10px;
    justify-content: left;
}

.admin-panel-data.order-list85 .top-title .e-status {
    text-align: left;
}

.admin-panel-data.order-list85 .title-value .top-value {
    max-height: 60px;
}

.admin-panel-data.order-list85 .book-list .book-row {
    position: relative;
    justify-content: left;
    align-items: baseline;
}

.admin-panel-data.order-list85 .book-list .book-row, .admin-panel-data.order-list85 .top-title  {
    grid-template-columns: 5% 5% 8% 16% 14% 4.5% 10% 11%  6% 9.5% 8% !important;
    gap: 5px;
}

.admin-panel-data.order-list85 .custom-dropdown {
    position: relative;
    border: 1px solid var(--green);
    border-radius: 10px;
    width: 145px;
    cursor: pointer;
    transition: all 0.3s ease-out 0s !important;
    background: url(http://localhost:3001/static/media/bg.b86e77a8782fe2bfbf29.png), var(--bg-color);
    overflow: hidden;
}

.admin-panel-data.order-list85 .custom-dropdown .selected-option {
    padding: 5px 15px 5px;
    font-size: 14px;
    line-height: 20px;
    font-family: var(--indivisible);
    position: relative;
}

.admin-panel-data.order-list85  .custom-dropdown::after {
    right: 15px;
    top: 14px;
    width: 11px;
    height: 8px;
}

.admin-panel-data.order-list85 .selected-option.category::after {
    display: none;
}

.admin-panel-data.order-list85 .fil-drop {
    position: absolute;
    width: -webkit-fill-available;
    top: 6px;
}

.admin-panel-data.order-list85 .custom-dropdown .dropdown-options {
    max-height: 300px;
    overflow: auto;
    background: var(--bg-color);
    z-index: 999;
    position: relative;
}

.order-list85-modal .mod-foter {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 20px;
}

.order-list85-modal .mod856 {
    display: grid;
    grid-gap: 8px;
}

.order-list85-modal .modal-title.h4 {
    font-size: 20px;
}

/* borrowed row */

.admin-panel-data.borrowed-list85 .top-title {
    grid-template-columns: 8.5% 11.5% 9% 10% 10% 10% 12% 15%  11% !important;
    gap: 5px;
    padding: 36px 16px 10px;
    justify-content: left;
}

.admin-panel-data.borrowed-list85 .top-title .e-status {
    text-align: left;
}

.admin-panel-data.borrowed-list85 .title-value .top-value {
    max-height: 60px;
}

.admin-panel-data.borrowed-list85 .book-list .book-row {
    grid-template-columns: 8.5% 11.5% 9% 10% 10% 10% 12% 15%  11% !important;
    gap: 5px;
    position: relative;
    justify-content: start;
    align-items: baseline;
}

.admin-panel-data.borrowed-list85 .custom-dropdown {
    position: relative;
    border: 1px solid var(--green);
    border-radius: 10px;
    width: 145px;
    cursor: pointer;
    transition: all 0.3s ease-out 0s !important;
    background: url(http://localhost:3001/static/media/bg.b86e77a8782fe2bfbf29.png), var(--bg-color);
    overflow: hidden;
}

.admin-panel-data.borrowed-list85 .custom-dropdown .selected-option {
    padding: 5px 15px 5px;
    font-size: 18px;
    line-height: 26px;
    font-family: var(--indivisible);
    position: relative;
}

.admin-panel-data.borrowed-list85  .custom-dropdown::after {
    right: 15px;
    top: 17px;
    width: 11px;
    height: 8px;
}

.admin-panel-data.borrowed-list85 .selected-option.category::after {
    display: none;
}

.admin-panel-data.borrowed-list85 .fil-drop {
    position: absolute;
    width: 189px;
    top: 11px;
}

.admin-panel-data.borrowed-list85 .custom-dropdown .dropdown-options {
    max-height: 300px;
    overflow: auto;
    background: var(--bg-color);
    z-index: 999;
    position: relative;
}

/* end borrowed */

.no-data {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;
    font-size: 20px;
    border-top: 1px solid var(--green);
    border-bottom: 1px solid var(--green);
    margin-top: 50px;
}

.small.text-danger {
    color: red !important;
    font-size: 15px;
    font-weight: 500;
    font-family: var(--indivisible);
}

.admin-panel-data.subscription-list .top-title {
    grid-template-columns: 11.5% 15.5% 18% 11% 10% 6% 11% 10%;
    gap: 1%;
}

.admin-panel-data.subscription-list .book-list .book-row {
    grid-template-columns: 11.5% 15.5% 18% 11% 10% 6% 11% 10%;
    gap: 1%;
}

.admin-panel-data.static-subscription .top-title {
    grid-template-columns: 14.5% 15.5% 22% 18% 15% 10%;
    gap: 1%;
}

.admin-panel-data.static-subscription .book-list .book-row {
    grid-template-columns: 14.5% 15.5% 22% 18% 15% 10%;
    gap: 1%;
}

.top-back-12 {
    background: var(--green);
    color: var(--white);
    font-family: var(--indivisible);
    border-radius: 5px;
    padding: 2px 20px;
}

.top-back-12:hover {
    background: var(--yellow);
    color: var(--green);
}

.cost-form-input .form-check-input:focus {
    border-color: var(--green);
    outline: 0;
    box-shadow: none;
}

.cost-form-input .form-check-input:checked[type=checkbox] {
    background: var(--green);
    --bs-form-check-bg-image: url("/public/assets/image/plan-list.png") !important;
    background-image: url("/public/assets/image/plan-list.png") !important;
    background-size: contain;
}

.cost-form-input  .form-check input, .cost-form-input  .form-check label {
    cursor: pointer;
}

.admin-panel-data.retrieval-order .top-title .e-status {
    text-align: left;
}

.admin-panel-data.list-defaulter .top-title.all-plans .e-status {
    text-align: left;
}

.admin-panel-data.list-defaulter .top-title.all-plans {
    display: grid;
    grid-template-columns: 18% 45% 15% 19%;
    gap: 1%;
    padding: 40px 16px 10px;
}

.admin-panel-data.list-defaulter  .book-list .book-row {
    display: grid;
    grid-template-columns: 18% 45% 15% 19% !important;
    gap: 1%;
}

/* new */

#file-input {
    display: none;
  }
  
.preview {
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 350px;
    margin: auto;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 0 20px rgba(170, 170, 170, 0.2);
}
  
.preview img {
    width: 100%;
    object-fit: cover;
    margin-bottom: 20px;
}
  
.preview label {
    font-weight: 600;
    cursor: pointer;
    color: #fff;
    border-radius: 8px;
    padding: 10px 20px;
    background-color: rgb(101, 101, 255);
}

.up-img152 {
    position: relative;
}

.up-img152 .upload-img-icon {
    position: absolute;
    bottom: -15px;
    right: 0;
}

.up-img152 .upload-img-icon label {
    width: 22px;
    height: 20px;
    overflow: hidden;
    border-radius: 5px;
    cursor: pointer;
    background: var(--white);
}

.up-img152 .upload-img-icon label img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    top: -5px;
    position: absolute;
    padding: 2px;
}

.drop-filter-main {
    width: 90%;
    margin: 0 auto;
    position: relative;
    padding: 11px 0px 10px;
}

.drop-filter-main  .drop-input {
    width: 100%;
    border-radius: 5px;
    border: 1px solid var(--green);
    font-size: 17px;
    color: var(--green);
    font-weight: 400;
    background: #ffffff !important;
    font-family: var(--indivisible);
    padding: 5px 11px;
}

.profile1-main .s-plan .plan-row.pay {
    grid-template-columns: 56% 44%;
}

.profile1-main .s-plan .plan-row.pay .ml-auto {
    margin-left: auto;
}

.web-header .social-left .searchdiv {
    position: absolute;
    right: 22px;
    top: -7px;
    width: 350px;
    background: var(--white);
    border-radius: 10px;
    border: 1.5px solid var(--green);
    box-shadow: 0px 0px 2px var(--dark-green);
    z-index: 999;
    overflow: hidden;
}

.web-header .social-left .show-on-click {
    position: absolute;
    border: 1.5px solid var(--green);
    box-shadow: 0px 0px 2px var(--dark-green);
    right: 22px;
    top: 46px;
    width: 350px;
    background: var(--bg-color);
    padding: 10px 20px;
    border-radius: 5px;
    max-height: 200px;
    overflow: auto;
    z-index: 99999;
}

.web-header .social-left .show-on-click .content {
    font-size: 16px;
    font-weight: 400;
    font-family: var(--indivisible);
    cursor: pointer;
    padding: 5px 0;
}

.web-header .social-left .show-on-click .content:hover {
    color: var(--yellow);
}

.web-header .social-left .show-on-click::-webkit-scrollbar {
    width: 5px;
    background: var(--white);
  }
  .web-header .social-left .show-on-click::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 5px;
  }

.web-header .social-left .searchdiv .btn-outline-secondary {
    border: 0;
    background: transparent !important;
    box-shadow: none;
}

.web-header .social-left .searchdiv .form-control {
    background: var(--white) !important;
    border: 0;
    font-family: var(--indivisible);
    color: var(--dark-green) !important;
    padding: 13px 20px !important;
}

.web-header .social-left .searchdiv .form-control:focus {
    color: var(--bs-body-color);
    background-color: var(--bs-body-bg);
    border-color: transparent;
    outline: 0;
    box-shadow: none;
}

.web-header .social-left .searchdiv .form-control::-webkit-input-placeholder {
    color: var(--dark-green) !important;
  }
  
  .web-header .social-left .searchdiv .form-control::-moz-placeholder {
    color: var(--dark-green) !important;
  }
  
  .web-header .social-left .searchdiv .form-control:-ms-input-placeholder {
    color: var(--dark-green) !important;
  }
  
  .web-header .social-left .searchdiv .form-control:-moz-placeholder {
    color: var(--dark-green) !important;
  }

.order-book-card .order-n.new15 {
    grid-column: 1 / span 3;
    display: grid;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    margin-top: 20px;
    grid-template-columns: repeat(3, 1fr);
}

.overview-clear-filter {
    padding-right: 90px;
    display: grid;
    grid-template-columns: 85% 10%;
    gap: 5%;
    margin-bottom: 0px;
}

.cost-form-group.login-show10 .pd-sh86 {
    position: absolute;
    right: 0;
    top: 3px;
    cursor: pointer;
    z-index: 99;
    height: 23px;
    display: block;
}

.cost-form-group.login-show10 .pd-sh86.j5 {
    padding-right: 13px;
}

.cost-form-group.login-show10 .pd-sh86 .hide-icon img{
    width: 20px;
}

.cost-form-group.login-show10 .pd-sh86 .show-icon img{
    width: 20px;
}

.t-and-c-main.privacy-policy {
    grid-template-columns: 100%;
}

h1.heading-h1.overview-subtitle.privacy-policy {
    width: 70%;
}

.login-form-main.rem58 .login-button.t-btn span {
    display: none;
}

.add-file-modal .down-sample {
    margin-bottom: 20px;
}

.add-file-modal .down-sample a {
    color: var(--white) !important;
    padding: 7px  20px;
    font-size: 14px;
}

.admin-panel-data .all-plans.inquiries {
    grid-template-columns: 21% 26% 19% 17% 13%;
    gap: 1%;
}

.admin-panel-data .book-list .book-row.all-plans.inquiries {
    grid-template-columns: 21% 26% 19% 17% 13% !important;
    gap: 1%;
}

.admin-panel-data .all-plans.inquiries .title.t-center {
    text-align: center !important;
}

.top-row.reading-t15 {
    grid-template-columns: 1fr 1fr;
    gap: 20px;
}

.top-row.reading-t15 .order-s-date {
    text-align: center;
}

.top-row.reading-t15 p.o-title {
    padding-bottom: 10px;
    font-weight: 700;
}

.for-mobile450 {
    text-align: center;
    color: var(--yellow);
    margin-bottom: 40px;
    display: none;
}

.product-detail .casualties ul {
    margin-left: 0;
    padding-left: 0px;
}

.detail.demand-slider.det852 {
    margin-bottom: 70px;
}

.contact-us-main .left .address .location.cta img {
    width: 19px;
    margin-right: 7px;
}

.house-header.fix-header .desktop-logo {
    transition: all 0.3s ease-out 0s !important;
    display: none;
}

.house-header.fix-header .fixed-menu-logo {
    transition: all 0.3s ease-out 0s !important;
    display: block;
    object-fit: contain;
}

.house-header.fix-header .header-logo-main {
    position: fixed;
    top: 0;
    left: 0;
}

.house-header.fix-header .header-logo-main {
    position: fixed;
    top: 0;
    left: 0;
    width: -moz-fit-content;
    width: fit-content;
    background: var(--green);
    border-radius: 0 0 15px 0;
    height: 80px;
    transition: all 0.3s ease-out 0s !important;
    justify-content: left;
    align-items: center;
}

.house-header.fix-header .header-logo-main .header-logo {
    width: 158px;
    height: 50px;
}

.sign-form-main.forgot526 .cost-form-group {
    grid-template-columns: 25% 75%;
    margin-bottom: 30px;
}

.overview-clear-filter {
    padding-bottom: 14px;
}

.top-title.member-pl5692 {
    grid-template-columns: 11.5% 12.5% 16% 16% 17% 12% 9% !important;
    justify-content: flex-start !important;
}

.book-row.member-plans904 {
    grid-template-columns: 11.5% 12.5% 16% 16% 17% 12% 9% !important;
    justify-content: flex-start !important;
}

.suits89ty {
    width: 50%;
    display: grid;
    grid-template-columns: 73% 22%;
    margin: 0 auto;
    gap: 5%;
}

.suits89ty .accept-reject {
    width: fit-content;
    margin-left: auto;
}

.sign-in-form-wrap .have-account .t-btn {
    font-weight: 500;
}

.login-form-main .pass-Forgot .t-btn {
    font-weight: 500;
}

.header-logo .mobile20-logo {
    display: none;
}

.book-panel-form .this-v1 {
    display: flex;
    justify-content: left;
    align-items: center;
    column-gap: 20px;
    row-gap: 4px;
    width: -webkit-fill-available;
    flex-wrap: wrap;
    background: #fff;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    border: 1px solid var(--green);
    height: 100%;
    max-height: 85px;
    overflow: auto;
}

.book-panel-form .this-v1::-webkit-scrollbar {
    width: 5px;
    background: var(--yellow);
  }

.book-panel-form .this-v1::-webkit-scrollbar-thumb {
    background: var(--green);
    border-radius: 5px;
  }

.book-panel-form .this-v1 p {
    position: relative;
    font-family: var(--indivisible);
}

.book-panel-form .this-v1 p span {
    position: absolute;
    content: "";
    right: -13px;
    top: -2px;
    width: 12px;
    height: 12px;
    background: url(/public/assets/image/close-new.png), var(--green);
    border-radius: 10px;
    background-size: cover;
    cursor: pointer;
}

.house-header .new-cta-s1 {
    display: flex;
    gap: 42px;
}

.top-row.reading-t15 .order-s-date .accept-reject:last-child {
    margin-left: 20px;
}

.cost-form-input .mr-v1 {
    margin-right: 28px;
}

.top-row.reding-list-vs1 {
    grid-template-columns: 57% 40% !important;
}

.top-row.top-row.reding-list-vs1 .order-s-date {
    text-align: left;
}

.top-row.reading-t15 p.o-title {
    padding-bottom: 10px;
    font-weight: 700;
    margin-left: 12px;
}

.mobile-menu-link .top-main .header-logo .fixed-for-ms1 {
    display: none;
}

.house-header.green-hover .header-btn-y:hover {
    color: var(--dark-green) !important;
}

.house-header.green-hover .header-btn-y:hover::after {
    background: var(--dark-green);
}

.house-header.green-hover .header-btn-y:hover span {
    filter: invert(1);
}

.overview-clear-filter .heading-h5 {
    font-size: 18px;
    line-height: 22px;
}

.overview-clear-filter .accept-reject {
    padding: 5px 24px;
    border-radius: 20px;
    font-size: 16px;
    width: max-content;
    margin-left: auto;
}

.overview-card-row .fiction-slide-card .hero-hover .hover-content .info {
    width: calc(100% - 100px);
}

.admin-panel-data.order-list85 .e-edit-delete {
    text-align: left;
}

.house-footer .footer-top .f-detail .pb-v1 {
    padding-bottom: 16px;
}

.cust-delivery-time {
    position: relative;
    margin-top: 15px;
}

.cust-delivery-time select {
    margin-right: 10px;
    padding: 5px 10px;
}

.cust-delivery-time select {
    margin-right: 15px;
    padding: 5px 6px;
    font-size: 13px;
    line-height: 23px;
    font-family: var(--indivisible);
    background: var(--dark-green);
    border: 1px solid var(--yellow);
    color: var(--white);
}

.cust-delivery-time select  option {
    margin-bottom: 20px !important;
}

.cust-delivery-time .title p {
    color: var(--yellow);
    font-family: var(--indivisible);
    margin-bottom: 15px;
}

.cust-delivery-time .title {
    margin-bottom: 6px;
}

.literary-cart .literary-row .schedule-return {
    margin-bottom: 20px;
}

.admin-panel-main .right .panel-title-top .right-side button {
    border-radius: 3px;
}

.demand-card .content .detail .more-info p {
    display: none;
}

.demand-card .content .detail .more-info p:first-child {
    display: block;
}

.admin-panel-data.borrowed-list85 {
    width: 100%;
    overflow: auto;
}

.admin-panel-data.borrowed-list85 .tb-res-v1 {
    width: 130%;
}

.admin-panel-data.borrowed-list85::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
}
  
.admin-panel-data.borrowed-list85::-webkit-scrollbar-thumb {
    background: var(--green); 
    border-radius: 5px;
}

.admin-panel-data.list_refunds-v1 .top-title, 
.admin-panel-data.list_refunds-v1 .book-row {
    grid-template-columns: 9% 16% 5.5% 5.5% 8% 5% 8% 5% 8% 9% 4% 12%;
    gap: 10px;
}

.admin-panel-data.list_refunds-v1 {
    overflow: auto;
    width: 100%;
}

.admin-panel-data.list_refunds-v1 .tb-res-v1 {
    width: 150%;
    padding-bottom: 90px;
}

.admin-panel-data.list_refunds-v1::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    background: var(--yellow);
    border-radius: 5px;
}
  
.admin-panel-data.list_refunds-v1::-webkit-scrollbar-thumb {
    background: var(--green); 
    border-radius: 5px;
}

.admin-panel-data.list_refunds-v1 .custom-dropdown {
    position: relative;
    border: 1px solid var(--green);
    border-radius: 10px;
    width: 145px;
    cursor: pointer;
    transition: all 0.3s ease-out 0s !important;
    background: url(http://localhost:3001/static/media/bg.b86e77a8782fe2bfbf29.png), var(--bg-color);
    overflow: hidden;
}

.admin-panel-data.list_refunds-v1 .custom-dropdown .selected-option {
    padding: 5px 15px 5px;
    font-size: 14px;
    line-height: 20px;
    font-family: var(--indivisible);
    position: relative;
}

.admin-panel-data.list_refunds-v1 .selected-option.category::after {
 display: none;
}
.admin-panel-data.list_refunds-v1 .book-row .title-value {
    position: relative;
}

.admin-panel-data.subscription-list .tb-res-v1 {
    width: 150% !important;
}

.admin-panel-data.subscription-list {
    position: relative !important;
}

.admin-panel-data.subscription-list .tb-res-v1 .book-row, .admin-panel-data.subscription-list .tb-res-v1 .top-title {
    gap: 10px!important;
    grid-template-columns: 11% 18% 7% 7% 8% 8% 8% 7% 6% 15% !important;
}

.contact-form .submit-cta .refundable-amount {
    margin-top: 0px;
    border-bottom: 1px solid var(--dark-green);
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.contact-form .submit-cta .refundable-amount p {
    text-align: right;
    font-size: 15px;
    line-height: 1.5rem;
    font-weight: 400;
    color: var(--dark-green);
    font-family: var(--indivisible);
}

.contact-form .submit-cta .refundable-amount p span {
    color: var(--dark-green);
    font-size: 16px;
    line-height: 1.5rem;
    font-weight: 600;
    /* font-family: var(--lora); */
}

.contact-form.payment-card-v1 .login-form-main .cost-form-input {
    margin-bottom: 17px;
    border-bottom: 0.5px solid #1347378a;
}

.contact-form.payment-card-v1 .login-form-main.chose-pln {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
    align-items: self-start;
}

.contact-form.payment-card-v1 .cost-form-group .form-label {
    font-size: 15px;
    margin-top: 0px;
}

.join-us-modal .choose-cards .top-title p {
    font-size: 15px;
    max-width: 80%;
    margin: 0 auto;
    text-align: center;
    line-height: 1.5rem;
    font-weight: 600;
    margin-top: 20px;
    font-family: var(--indivisible);
}

.join-us-modal .choose-cards .top-title p span {
    font-weight: 700;
    font-size: 18px;
    margin-right: 5px;
}

.join-us-modal .choose-cards .top-title p a {
    color: var(--yellow) !important;
}

.modal-dialog-centered .modal-footer {
    justify-content: space-between !important;
}

.t-and-c-main .right-content .m-card {
    scroll-margin: 4em;
}

.admin-panel-main .admin-panel-data .top-title {
    position: sticky;
    top: 0px;
    background: url("/public/assets/image/bg.png"), var(--bg-color);
    padding: 16px 16px 10px !important;
    z-index: 999;
    border-bottom: 1px solid var(--dark-green);
    margin-bottom: 21px;
}

.admin-panel-data .book-list {
    margin-bottom: 20px;
}

.admin-panel-data .tb-res-v1 {
    min-height: -moz-fit-content;
    min-height: fit-content;
    max-height: 75vh;
    overflow-x: auto;
    padding-right: 20px;
    margin-right: 20px;
    margin-bottom: 30px;
}

.admin-panel-data .tb-res-v1::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    background: var(--yellow);
    border-radius: 5px;
  }
  
  .admin-panel-data .tb-res-v1::-webkit-scrollbar-thumb {
    background: var(--green); 
    border-radius: 5px;
}

.admin-panel-data .book-list .note-modal {
    background: var(--dark-green);
    padding: 5px 9px 6px 13px;
    border-radius: 5px;
    position: relative;
}

.admin-panel-data .book-list .note-modal img {
    height: 20px;
    width: fit-content;
    object-fit: contain;
}

.admin-panel-data.list_refunds-v1 .fil-drop {
    display: grid;
    grid-template-columns: 164px auto;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    width: -webkit-fill-available;
    top: -17px;
    gap: 10px;
}

.admin-panel-data.subscription-list .not-mod-v1 {
    display: grid;
    grid-template-columns: 225px auto;
    justify-content: space-between;
    align-items: flex-start;
    width: -webkit-fill-available;
    gap: 20px;
}

.faq-accordion .accordion-body a {
    color: var(--yellow) !important;
    font-weight: 500;
}

.admin-panel-data.admin-user-list .top-title, 
.admin-panel-data.admin-user-list .book-list .book-row {
    display: grid;
    grid-template-columns: 20% 28.5% 11% 11% 11% 12%;
    gap: 5px;
}