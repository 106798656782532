@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap");

@import url("https://fonts.adobe.com/fonts/indivisible");

:root {
  /* colors used */
  --white: #f8f5e9;
  --dark-green: #134737;
  --green: #056047;
  --yellow: #dcb86b;
  --bg-color: #f8f5e9;
  --notify: #d10069;

  /* font families */
  --lora: "Lora", serif;
  --indivisible: "indivisible", sans-serif;

  /* style guide for 1920 */
  --h1-font-size: 60px;
  --h1-line-height: 70px;

  --h2-font-size: 46px;
  --h2-line-height: 44px;

  --h3-font-size: 28px;
  --h3-line-height: 34px;

  --h4-font-size: 24px;
  --h4-line-height: 34px;

  --h5-font-size: 22px;
  --h5-line-height: 34px;

  --h6-font-size: 14px;
  --h6-line-height: 26px;

  --body-font-size: 18px;
  --body-line-height: 26px;

  --login-title: 60px;
  --login-line-height: 66px;
}

::placeholder {
  color: #13473769 !important;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #13473769 !important;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--dark-green);
  border-radius: 5px;
}

button {
  border: 0;
  background: transparent;
}

/* button */
.t-btn {
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  font-family: var(--indivisible);
  display: block;
  position: relative;
  padding: 0px;
  font-weight: 600;
  transition: all 200ms ease-in;
  width: -moz-fit-content;
  width: fit-content;
  height: max-content;
}

.t-btn span {
  margin-left: 6px;
  height: fit-content;
  width: 20px;
  display: inline-block;
  transition: all 200ms ease-in;
}

.t-btn::after {
  position: absolute;
  content: "";
  width: calc(100% - 30px);
  height: 1.5px;
  bottom: 0;
  left: 0;
  transition: all 200ms ease-in;
}

/* custom button */
.cost-btn {
  color: var(--dark-green) !important;
}

.cost-btn span {
  background: url("/public/assets/image/arrow-green-bg.png") no-repeat center;
}

.cost-btn:hover {
  color: var(--yellow) !important;
}

.cost-btn::after {
  background: var(--dark-green);
}

.cost-btn:hover::after {
  background: var(--yellow);
}

.cost-btn:hover span {
  background: url("/public/assets/image/arrow-yellow-bg.png") no-repeat center;
}

/* btn 2 */
.cost-btn-w {
  font-family: var(--indivisible);
  color: var(--dark-green) !important;
}

.cost-btn-w span {
  background: url("/public/assets/image/arrow-green-bg.png") no-repeat center;
}

.cost-btn-w::after {
  background: var(--dark-green);
}

.cost-btn-w:hover {
  color: var(--white) !important;
}

.cost-btn-w:hover::after {
  background: var(--white);
}

.cost-btn-w:hover span {
  background: url("/public/assets/image/arrow-right-white.png") no-repeat center;
}

/* white btn */
.white-btn-w {
  font-family: var(--indivisible);
  color: var(--white) !important;
}

.white-btn-w span {
  background: url("/public/assets/image/arrow-right-white.png") no-repeat center;
}

.white-btn-w::after {
  background: var(--white);
}

.white-btn-w:hover {
  color: var(--yellow) !important;
}

.white-btn-w:hover::after {
  background: var(--yellow);
}

.white-btn-w:hover span {
  background: url("/public/assets/image/arrow-yellow-bg.png") no-repeat center;
}

/* header btn */
.header-btn-y {
  font-family: var(--indivisible);
  color: var(--yellow) !important;
}

.header-btn-y span {
  background: url("/public/assets/image/arrow-yellow-bg.png") no-repeat center;
}

.header-btn-y::after {
  background: var(--yellow);
}

.header-btn-y:hover {
  color: var(--white) !important;
}

.header-btn-y:hover::after {
  background: var(--white);
}

.header-btn-y:hover span {
  background: url("/public/assets/image/arrow-right-white.png") no-repeat center;
}

/* button with yellow background */

.login-button {
  font-family: var(--indivisible);
  color: var(--dark-green) !important;
  background: var(--yellow) !important;
  padding: 13px 70px;
}

.login-button span {
  background: url("/public/assets/image/arrow-right-green.png") no-repeat center;
}

.login-button.clear-btn-v1 span {
  background: url("/public/assets/image/reload-green.png") no-repeat center;
  background-size: contain;
}

.login-button.clear-btn-v1:hover span {
  background: url("/public/assets/image/reload-white.png") no-repeat center;
  background-size: contain;
}

.login-button::after {
  display: none;
}

.login-button:hover {
  color: var(--white) !important;
  background: var(--dark-green) !important;
}

.login-button:hover::after {
  background: var(--yellow);
}

.login-button:hover span {
  background: url("/public/assets/image/arrow-right-white.png") no-repeat center;
}

/* button without arrow */
.cost-a {
  font-family: var(--indivisible);
  color: var(--dark-green) !important;
}

.cost-a::after {
  display: none;
}

.cost-a:hover {
  color: var(--yellow);
}

.cost-a::after {
  color: var(--yellow);
}

/* heading */
h1,
.heading-h1 {
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
}

h2,
.heading-h2 {
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
}

h3,
.heading-h3 {
  font-size: var(--h3-font-size);
  line-height: var(--h3-line-height);
}

h4,
.heading-h4 {
  font-size: var(--h4-font-size);
  line-height: var(--h4-line-height);
}

h5,
.heading-h5 {
  font-size: var(--h5-font-size);
  line-height: var(--h5-line-height);
}

h6,
.heading-h6 {
  font-size: var(--h6-font-size);
  line-height: var(--h6-line-height);
}

.login-title {
  font-size: var(--login-title);
  line-height: var(--login-line-height);
  text-transform: uppercase;
  color: var(--green);
}

p {
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  margin-bottom: 0 !important;
}

.img-box {
  position: relative;
  overflow: hidden;
}

.img-box img,
.img-box video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

body {
  margin: 0;
  background: url("/public/assets/image/bg.png"), var(--bg-color);
  font-family: var(--lora) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--body-font-size) !important;
  line-height: var(--body-line-height) !important;
  color: var(--dark-green) !important;
}

.house_container {
  width: 84.5%;
  padding-right: 90px;
}

section {
  position: relative;
}

a {
  -webkit-transition: all 0.3s ease-out 0s !important;
  -moz-transition: all 0.3s ease-out 0s !important;
  -ms-transition: all 0.3s ease-out 0s !important;
  -o-transition: all 0.3s ease-out 0s !important;
  transition: all 0.3s ease-out 0s !important;
  cursor: pointer;
  text-decoration: none !important;
  color: var(--dark-green) !important;
  transition: all 200ms ease-in !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: var(--green) !important;
}

ul {
  list-style-type: none;
  margin: 0px;
}

/* input style */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: var(--white);
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: transparent;
  outline: 0 !important;
  color: var(--white) !important;
}

input:focus,
.form-control:focus {
  box-shadow: none;
  outline: 0;
  background: transparent;
}

.form-controla {
  color: var(--dark-green);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  border-bottom: 1.5px solid var(--yellow) !important;
  font-family: var(--indivisible);
  border: none;
  background-color: transparent;
  border-radius: 0;
  padding: 6px 12px;
  width: 100%;
  font-weight: inherit;
  height: auto;
  min-height: auto;
  outline: 0;
  font-style: italic;
}

.cost-form-group label.form-label {
  font-size: var(--h5-font-size);
  line-height: var(--h5-line-height);
  color: var(--green);
  margin-bottom: 15px;
  font-weight: 500;
}

.cost-form-group {
  border-bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: flex-start;
  align-items: self-start;
  margin-bottom: 10px;
  position: relative;
}

::placeholder {
  color: var(--green);
  opacity: 1;
}

::-ms-input-placeholder {
  color: var(--green);
}

.notify-btn {
  background: transparent;
  border: 1.5px solid var(--notify);
  color: var(--notify);
  padding: 13px 70px;
  margin-bottom: 20px;
}

.notify-btn span {
  background: url("/public/assets/image/notify-right.svg") no-repeat center;
}

.notify-btn:hover {
  color: var(--yellow) !important;
}

/* .notify-btn::after {
  background: var(--notify);
} */

.notify-btn:hover::after {
  background: var(--yellow);
}

.notify-btn:hover span {
  background: url("/public/assets/image/arrow-yellow-bg.png") no-repeat center;
}

/* toastify color */

.Toastify__toast-theme--light {
  background: var(--white) !important;
  color: var(--dark-green) !important;
}

.Toastify__toast-icon svg {
  fill: var(--dark-green) !important;
}

.Toastify__progress-bar--success {
  background: var(--dark-green) !important;
}

.Toastify__close-button--light {
  color: var(--dark-green) !important;
  opacity: 100% !important;
}

.Toastify__progress-bar {
  z-index: var(--toastify-z-index);
  opacity: 100% !important;
}
