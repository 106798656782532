.loader-theme-outer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,.5);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999999;
  }

  .loader-theme {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    color: var(--white);
    -webkit-animation: animloader42 1s linear infinite alternate;
            animation: animloader42 1s linear infinite alternate;
  }

  @keyframes animloader42 {
    0% {
      box-shadow: -38px -6px, -14px 6px, 14px -6px;
    }
    33% {
      box-shadow: -38px 6px, -14px -6px, 14px 6px;
    }
    66% {
      box-shadow: -38px -6px, -14px 6px, 14px -6px;
    }
    100% {
      box-shadow: -38px 6px, -14px -6px, 14px 6px;
    }
  }
  
  .loader-theme.loader-theme-dark{
    color: var(--navy-blue);
  }